import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapActionWithProgress } from '@/store';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { AcceptHorseShareInvite, acceptHorseShareInvite, RejectHorseShareInvite, rejectHorseShareInvite } from '@/feature/horse-management';
import { cancelRidingLessonRegistration, getCurrentPenStatusEntry, getHorseShareInvites, getNextAppointments, getNotificationStatus, getUnseenNotifications, markAllNotificationAsSeen, markNotificationAsSeen } from './service';
import { Appointment, CancelRidingLessonRegistrationCommand, HorseShareInvite, MarkNotificationAsSeenCommand, PenStatusEntry, UnseenNotification } from './types';

interface DashboardState {
  currentPenStatusEntry: PenStatusEntry | null;
  nextAppointments: Appointment[] | null;
  horseShareInvites: HorseShareInvite[];
  unseenNotifications: UnseenNotification[];
  hasUnhandledNotifications: boolean;

  getNextAppointmentsStatus: ActionStatus;
  getCurrentPenStatusEntryStatus: ActionStatus;
  acceptHorseShareInviteStatus: ActionStatus;
  rejectHorseShareInviteStatus: ActionStatus;
  getUnseenNotificationsStatus: ActionStatus;
  getHorseShareInvitesStatus: ActionStatus;
  markNotificationAsSeenStatus: ActionStatus;
  markAllNotificationAsSeenStatus: ActionStatus;
  getNotificationStatusStatus: ActionStatus;
  cancelRidingLessonRegistrationStatus: ActionStatus;
}

function initialState(): DashboardState {
  return {
    currentPenStatusEntry: null,
    nextAppointments: null,
    horseShareInvites: [],
    unseenNotifications: [],
    hasUnhandledNotifications: false,

    getNextAppointmentsStatus: ActionStatus.None,
    getCurrentPenStatusEntryStatus: ActionStatus.None,
    acceptHorseShareInviteStatus: ActionStatus.None,
    rejectHorseShareInviteStatus: ActionStatus.None,
    getUnseenNotificationsStatus: ActionStatus.None,
    getHorseShareInvitesStatus: ActionStatus.None,
    markNotificationAsSeenStatus: ActionStatus.None,
    markAllNotificationAsSeenStatus: ActionStatus.None,
    getNotificationStatusStatus: ActionStatus.None,
    cancelRidingLessonRegistrationStatus: ActionStatus.None,
  };
}

export const useDashboardStore = defineStore('dashboard', {
  state: (): DashboardState => initialState(),
  getters: {
    isGetNextAppointmentsProcessing: (state: DashboardState): boolean =>
      state.getNextAppointmentsStatus === ActionStatus.InProgress,
    isGetCurrentPenStatusEntryProcessing: (state: DashboardState): boolean =>
      state.getCurrentPenStatusEntryStatus === ActionStatus.InProgress,
    isAcceptHorseShareInviteProcessing: (state: DashboardState): boolean =>
      state.acceptHorseShareInviteStatus === ActionStatus.InProgress,
    isRejectHorseShareInviteProcessing: (state: DashboardState): boolean =>
      state.rejectHorseShareInviteStatus === ActionStatus.InProgress,
    isGetUnseenNotificationsProcessing: (state: DashboardState): boolean =>
      state.getUnseenNotificationsStatus === ActionStatus.InProgress,
    isGetHorseShareInvitesProcessing: (state: DashboardState): boolean =>
      state.getHorseShareInvitesStatus === ActionStatus.InProgress,
    isMarkNotificationAsSeenProcessing: (state: DashboardState): boolean =>
      state.markNotificationAsSeenStatus === ActionStatus.InProgress,
    isMarkAllNotificationAsSeenProcessing: (state: DashboardState): boolean =>
      state.markAllNotificationAsSeenStatus === ActionStatus.InProgress,
    isGetNotificationStatusProcessing: (state: DashboardState): boolean =>
      state.getNotificationStatusStatus === ActionStatus.InProgress,
    isCancelRidingLessonRegistrationProcessing: (state: DashboardState): boolean =>
      state.cancelRidingLessonRegistrationStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- Queries

    getNextAppointments(): Promise<void> {
      const { getNextAppointmentsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getNextAppointmentsStatus,
        () => getNextAppointments(attachFarmAndUserProperties({}))
          .then(async (nextAppointments) => {
            this.nextAppointments = nextAppointments;
          })
      );
    },

    getCurrentPenStatusEntry(): Promise<void> {
      const { getCurrentPenStatusEntryStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getCurrentPenStatusEntryStatus,
        () => getCurrentPenStatusEntry(attachFarmAndUserProperties({}))
          .then(async (penStatusResponse) => {
            this.currentPenStatusEntry = penStatusResponse.penStatusEntry;
          })
      );
    },

    getUnseenNotifications(): Promise<void> {
      const { getUnseenNotificationsStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getUnseenNotificationsStatus,
        () => getUnseenNotifications(attachFarmAndUserProperties({}))
          .then(async (unseenNotifications) => {
            this.unseenNotifications = unseenNotifications;
            this.hasUnhandledNotifications = unseenNotifications.length > 0;
          })
      );
    },

    getHorseShareInvites(): Promise<void> {
      const { getHorseShareInvitesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getHorseShareInvitesStatus,
        () => getHorseShareInvites(attachFarmAndUserProperties({}))
          .then(async (horseShareInvites) => {
            this.horseShareInvites = horseShareInvites;
          })
      );
    },

    getNotificationStatus(): Promise<void> {
      const { getNotificationStatusStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getNotificationStatusStatus,
        () => getNotificationStatus(attachFarmAndUserProperties({}))
          .then(async (notificationStatus) => {
            this.hasUnhandledNotifications = notificationStatus.hasUnhandledNotifications;
          })
      );
    },

    // -- Commands

    acceptHorseShareInvite(command: AcceptHorseShareInvite.AcceptHorseShareInviteCommand): Promise<void> {
      const { acceptHorseShareInviteStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        acceptHorseShareInviteStatus,
        () => acceptHorseShareInvite(attachFarmAndUserProperties(command))
          .then(() => this.getHorseShareInvites())
      );
    },

    rejectHorseShareInvite(command: RejectHorseShareInvite.RejectHorseShareInviteCommand): Promise<void> {
      const { rejectHorseShareInviteStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        rejectHorseShareInviteStatus,
        () => rejectHorseShareInvite(attachFarmAndUserProperties(command))
          .then(() => this.getHorseShareInvites())
      );
    },

    // Doesn't refresh the seen notifications as this can also happen outside the dashboard
    markNotificationAsSeen(command: MarkNotificationAsSeenCommand): Promise<void> {
      const { markNotificationAsSeenStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markNotificationAsSeenStatus,
        () => markNotificationAsSeen(attachFarmAndUserProperties(command))
      );
    },

    markAllNotificationAsSeen(): Promise<void> {
      const { markAllNotificationAsSeenStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        markAllNotificationAsSeenStatus,
        () => markAllNotificationAsSeen(attachFarmAndUserProperties({}))
          .then(() => this.getUnseenNotifications())
      );
    },

    cancelRidingLessonRegistration(command: CancelRidingLessonRegistrationCommand): Promise<void> {
      const { cancelRidingLessonRegistrationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        cancelRidingLessonRegistrationStatus,
        () => cancelRidingLessonRegistration(attachFarmAndUserProperties(command))
          .then(() => this.getNextAppointments())
      );
    },

  },
});
