import { Command, Query } from '@/application/types';
import { FacilityId, RidingLessonTypeId, HorseId, RidingLessonDuration, RidingLessonId, RidingLessonRegistrationId, RidingLessonRegistrationType, UserId, Strain, Color, TimeFrame, UserIdentification, Weekday, RidingLessonSeriesId, Time, DateTimeFrame, Date, Moment } from '@/types';

// -- Models

export interface Configuration {
  isRidingLessonPlanVisible: boolean;
  idsOfHorsesAvailableForAssignment: HorseId[];
  areHorsesFilteredByRidingLessonType: boolean;
  isRidingInstructorShownOnPlan: boolean;
  isWaitingListAvailable: boolean;
  waitingListNotice: string | null;
  idsOfHorsesAvailableForAssignmentForRidingLessonTypeMap: Record<RidingLessonTypeId, HorseId[]>;
}

export interface Facility {
  facilityId: FacilityId;
  name: string;
  spaces: number;
  openingHours: TimeFrame;
  ridingLessonConfiguration: RidingLessonConfigurationForFacility | null;
  ridingLessonTypes: RidingLessonType[];
}

export interface RidingLessonType {
  ridingLessonTypeId: RidingLessonTypeId;
  name: string;
  requiredSpaces: number;
  strainOnHorse: Strain;
  color: Color;
  defaultDuration: RidingLessonDuration;
  registrationInAdvanceInDays: number;
  idsOfHorsesAvailableForAssignment: HorseId[];
  isEnabled: boolean;
}

export interface RidingLessonIdentification {
  ridingLessonId: RidingLessonId;
  name: string;
}

export interface FarmManager {
  userId: UserId;
  name: string;
}

export interface Horse {
  horseId: HorseId;
  name: string;
}

export interface HorseWithUtilization extends Horse {
  days: RidingLessonActivity[][];
}

export interface HorseUtilization {
  horses: HorseWithUtilization[];
  days: Date[];
}

export interface RidingLessonActivity {
  duration: RidingLessonDuration;
  strainOnHorse: Strain;
}

export interface RidingLessonConfigurationForFacility {
  isRegistrationForOneselfAllowed: boolean;
  isRegistrationOnBehalfOfAnotherPersonAllowed: boolean;
  explanationForCommentFieldForRegistration: string | null;
}

export interface FacilityIdentification {
  facilityId: FacilityId;
  name: string;
}

export interface RidingLessonTypeIdentification {
  ridingLessonTypeId: RidingLessonTypeId;
  name: string;
}

export interface RidingLesson {
  ridingLessonId: RidingLessonId;
  facility: FacilityIdentification;
  ridingLessonType: RidingLessonTypeIdentification;
  ridingLessonSeries: RidingLessonSeries | null;
  from: Moment;
  to: Moment;
  duration: number;
  lastRegistrationAt: Moment;
  ridingInstructorId: UserId | null;
  maxAmountOfParticipants: number | null;
  horses: Horse[];
  registrations: RidingLessonRegistration[];
  facilityOverlappingWithOtherRidingLessons: RidingLessonId[];
  ridingInstructorOverlappingWithOtherRidingLessons: RidingLessonId[];
  horsesOverlappingWithOtherRidingLessonsMap: Record<HorseId, RidingLessonId> | null;
  horsesWithDuplicateAssignments: HorseId[];
}

export interface RidingLessonSeries {
  ridingLessonSeriesId: RidingLessonSeriesId;
  weekday: Weekday;
  timeFrame: TimeFrame;
  daysBeforeStartForLastRegistrationAt: number;
  timeForLastRegistrationAt: Time;
  lastRidingLessonAt: Moment;
  ridingInstructorId: UserId | null;
  maxAmountOfParticipants: number | null;
}

export interface RidingLessonRegistration {
  ridingLessonRegistrationId: RidingLessonRegistrationId;
  user: UserIdentification;
  type: RidingLessonRegistrationType;
  horse: Horse | null;
  nameOfAnotherPerson: string | null;
  comment: string | null;
  registeredAt: Moment;
}

export enum UtilizationRange {
  ONE_DAY = 'ONE_DAY',
  THREE_DAYS = 'THREE_DAYS',
}

export interface RidingLessonForSeriesRegistration {
  ridingLessonId: RidingLessonId;
  name: string;
}

export interface UserForRidingLessonRegistration {
  userId: UserId;
  name: string;
}

// -- Queries

export interface GetRidingLessonsForSeriesRegistrationAsManagerQuery extends Query {
  ridingLessonSeriesId: RidingLessonSeriesId;
}

// -- Commands

export interface CreateRidingLessonTypeAsManagerCommand extends Command {
  facilityId: FacilityId;
  name: string;
  strainOnHorse: Strain;
  defaultDuration: RidingLessonDuration;
  requiredSpaces: number;
  registrationInAdvanceInDays: number;
  idsOfHorsesAvailableForAssignment: HorseId[] | null;
}

export interface UpdateNameOfRidingLessonTypeAsManagerCommand extends Command {
  ridingLessonTypeId: RidingLessonTypeId;
  name: string;
}

export interface UpdateRequiredSpacesOfRidingLessonTypeAsManagerCommand extends Command {
  ridingLessonTypeId: RidingLessonTypeId;
  requiredSpaces: number;
}

export interface UpdateDefaultDurationOfRidingLessonTypeAsManagerCommand extends Command {
  ridingLessonTypeId: RidingLessonTypeId;
  defaultDuration: RidingLessonDuration;
}

export interface UpdateStrainOnHorseOfRidingLessonTypeAsManagerCommand extends Command {
  ridingLessonTypeId: RidingLessonTypeId;
  strainOnHorse: Strain;
}

export interface EnableRidingLessonTypeAsManagerCommand extends Command {
  ridingLessonTypeId: RidingLessonTypeId;
}

export interface DisableRidingLessonTypeAsManagerCommand extends Command {
  ridingLessonTypeId: RidingLessonTypeId;
}

export interface UpdateOrderOfRidingLessonTypesOfFacilityAsManagerCommand extends Command {
  facilityId: FacilityId;
  sortedIdsOfRidingLessonTypes: RidingLessonTypeId[];
}

export interface UpdateRegistrationInAdvanceOfRidingLessonTypeAsManagerCommand extends Command {
  ridingLessonTypeId: RidingLessonTypeId;
  registrationInAdvanceInDays: number;
}

export interface UpdateHorsesForAssignmentOfRidingLessonTypeAsManagerCommand extends Command {
  ridingLessonTypeId: RidingLessonTypeId;
  idsOfHorsesAvailableForAssignment: HorseId[];
}

export interface UpdateRidingLessonRegistrationRestrictionsForFacilityAsManagerCommand extends Command {
  facilityId: FacilityId;
  isRegistrationForOneselfAllowed: boolean;
  isRegistrationOnBehalfOfAnotherPersonAllowed: boolean;
  explanationForCommentFieldForRegistration: string | null;
}

export interface SetUpRidingLessonsForFacilityAsManagerCommand extends Command {
  facilityId: FacilityId;
  isRegistrationForOneselfAllowed: boolean;
  isRegistrationOnBehalfOfAnotherPersonAllowed: boolean;
  explanationForCommentFieldForRegistration: string | null;
}

export interface CreateRidingLessonAsManagerCommand extends Command {
  facilityId: FacilityId;
  ridingLessonTypeId: RidingLessonTypeId;
  from: Moment;
  to: Moment;
  duration: number;
  lastRegistrationAt: Moment;
  ridingInstructorId: UserId | null;
  maxAmountOfParticipants: number | null;
}

export interface CreateRidingLessonSeriesAsManagerCommand extends Command {
  facilityId: FacilityId;
  ridingLessonTypeId: RidingLessonTypeId;
  weekday: Weekday;
  from: Moment;
  to: Moment;
  duration: number;
  lastRegistrationAt: Moment;
  lastRidingLessonAt: Moment;
  ridingInstructorId: UserId | null;
  maxAmountOfParticipants: number | null;
}

export interface DeleteRidingLessonAsManagerCommand extends Command {
  ridingLessonId: RidingLessonId;
}

export interface DeleteRidingLessonRegistrationAsManagerCommand extends Command {
  ridingLessonRegistrationId: RidingLessonRegistrationId;
}

export interface CreateRidingLessonConfigurationAsManagerCommand extends Command {
  isRidingLessonPlanVisible: boolean;
  idsOfHorsesAvailableForAssignment: HorseId[];
  areHorsesFilteredByRidingLessonType: boolean;
  isRidingInstructorShownOnPlan: boolean;
  isWaitingListAvailable: boolean;
  waitingListNotice: string | null;
}

export interface UpdateRidingLessonConfigurationAsManagerCommand extends Command {
  isRidingLessonPlanVisible: boolean;
  idsOfHorsesAvailableForAssignment: HorseId[];
  areHorsesFilteredByRidingLessonType: boolean;
  isRidingInstructorShownOnPlan: boolean;
  isWaitingListAvailable: boolean;
  waitingListNotice: string | null;
}

export interface AssignHorseToRidingLessonAsManagerCommand extends Command {
  ridingLessonId: RidingLessonId;
  horseId: HorseId;
}

export interface AssignHorseToRidingLessonRegistrationAsManagerCommand extends Command {
  ridingLessonRegistrationId: RidingLessonRegistrationId;
  horseId: HorseId;
}

export interface RemoveHorseFromRidingLessonAsManagerCommand extends Command {
  ridingLessonId: RidingLessonId;
  horseId: HorseId;
}

export interface RemoveHorseFromRidingLessonRegistrationAsManagerCommand extends Command {
  ridingLessonRegistrationId: RidingLessonRegistrationId;
}

export interface UpdateRidingInstructorOfRidingLessonAsManagerCommand extends Command {
  ridingLessonId: RidingLessonId;
  ridingInstructorId: UserId | null;
}

export interface UpdateMaxAmountOfParticipantsOfRidingLessonAsManagerCommand extends Command {
  ridingLessonId: RidingLessonId;
  maxAmountOfParticipants: number | null;
}

export interface UpdateTimeFrameOfRidingLessonAsManagerCommand extends Command {
  ridingLessonId: RidingLessonId;
  from: Moment;
  to: Moment;
  duration: number;
  lastRegistrationAt: Moment;
}

export interface UpdateNameOfAnotherPersonOfRidingLessonRegistrationAsManagerCommand extends Command {
  ridingLessonRegistrationId: RidingLessonRegistrationId;
  nameOfAnotherPerson: string;
}

export interface UpdateCommentOfRidingLessonRegistrationAsManagerCommand extends Command {
  ridingLessonRegistrationId: RidingLessonRegistrationId;
  comment: string | null;
}

export interface MoveRidingLessonRegistrationToDifferentRidingLessonAsManagerCommand extends Command {
  ridingLessonRegistrationId: RidingLessonRegistrationId;
  ridingLessonId: RidingLessonId;
}

export interface UpdateFacilityAndRidingLessonTypeOfRidingLessonAsManagerCommand extends Command {
  ridingLessonId: RidingLessonId;
  facilityId: FacilityId;
  ridingLessonTypeId: RidingLessonTypeId;
}

export interface ExtendRidingLessonSeriesAsManagerCommand extends Command {
  ridingLessonSeriesId: RidingLessonSeriesId;
  ridingLessonId: RidingLessonId;
  lastRidingLessonAt: Moment;
}

export interface ShortenRidingLessonSeriesAsManagerCommand extends Command {
  ridingLessonSeriesId: RidingLessonSeriesId;
  ridingLessonId: RidingLessonId;
  lastRidingLessonAt: Moment;
}

export interface UpdateRidingInstructorOfRidingLessonSeriesAsManagerCommand extends Command {
  ridingLessonSeriesId: RidingLessonSeriesId;
  ridingLessonId: RidingLessonId;
  ridingInstructorId: UserId | null;
}

export interface UpdateMaxAmountOfParticipantsOfRidingLessonSeriesAsManagerCommand extends Command {
  ridingLessonSeriesId: RidingLessonSeriesId;
  ridingLessonId: RidingLessonId;
  maxAmountOfParticipants: number | null;
}

export interface UpdateLastRegistrationAtOfRidingLessonSeriesAsManagerCommand extends Command {
  ridingLessonSeriesId: RidingLessonSeriesId;
  ridingLessonId: RidingLessonId;
  lastRegistrationAt: Moment;
}

export interface UpdateWeekdayAndTimeFrameOfRidingLessonSeriesAsManagerCommand extends Command {
  ridingLessonSeriesId: RidingLessonSeriesId;
  ridingLessonId: RidingLessonId;
  weekday: Weekday;
  timeFrame: TimeFrame;
}

// -- Queries

export interface GetRidingLessonsAsManagerQuery extends Query {
  date: Date;
}

export interface GetRidingLessonsForSelectionAsManagerQuery extends Query {
  date: Date;
}

export interface GetHorsesForAssignmentWithUtilizationAsManagerQuery extends Query {
  date: Date;
  utilizationRange: UtilizationRange;
}

export interface GetHorseUtilizationAsManagerQuery extends Query {
  dateTimeFrame: DateTimeFrame;
}

// -- Responses

export interface GetConfigurationResponse {
  facilities: Facility[];
  farmManagers: FarmManager[];
  horses: Horse[];
  configuration: Configuration | null;
}
