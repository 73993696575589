import { Ref } from 'vue';
import { ActionStatus } from '@/application/types';
import { APIResource } from '@/infrastructure';

type Action<T> = () => Promise<T>;

export async function wrapActionWithProgress<T>(
  statusProperty: Ref<ActionStatus>,
  action: Action<T>
): Promise<T> {
  statusProperty.value = ActionStatus.InProgress;
  return action()
    .then((response: T) => {
      statusProperty.value = ActionStatus.Successful;
      return Promise.resolve(response);
    })
    .catch((error) => {
      statusProperty.value = ActionStatus.Failed;
      return Promise.reject(error);
    });
}

export async function wrapAction<T>(
  property: Ref<APIResource<T>>,
  action: Action<T>
): Promise<void> {
  property.value.status = ActionStatus.InProgress;
  return action()
    .then((response: T) => {
      property.value.value = response;
      property.value.status = ActionStatus.Successful;
      return Promise.resolve();
    })
    .catch((error) => {
      property.value.value = null;
      property.value.status = ActionStatus.Failed;
      return Promise.reject(error);
    });
}
