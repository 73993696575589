import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress } from '@/store';
import { ActionStatus } from '@/application/types';
import { useAuthenticationStore } from '@/application/authentication/store';
import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { FacilityReservationActivityId, HorseId, MomentTimeFrame, UserId } from '@/types';
import { deleteFacilityReservation, createFacilityReservationActivity, disableFacilityReservationActivity, enableFacilityReservationActivity, getFacilityReservationsForFacility, loadFacilities, setUpFacilityReservationsForFacility, updateTimeBasedRestrictionsOfFacilityReservationActivity, updateColorOfFacilityReservationActivity, updateReservationDisplayNameTypeOfFacility, updateBookingInAdvanceOfFacility, updateBookingNoticeOfFacility, updateMaxUsageOfFacility, updateMinDistanceToOwnBookingsWithDifferentHorsesIntervalsOfFacility, updateTimeOptionIntervalOfFacility, updateNameOfFacilityReservationActivity, updateOrderOfFacilityReservationActivitiesOfFacility, updateRequiredSpacesOfFacilityReservationActivity, updateTimeOptionIntervalRangeOfFacilityReservationActivity, allowParallelReserverationInOtherFacilitiesForFacility, forbidParallelReserverationInOtherFacilitiesForFacility, updateIsRiderAbleToReserveMultipleParallelActivitiesOfFacilityReservationActivity, getEvaluationFilterData, getEvaluationByUser, getEvaluationByHorseOwner } from './service';
import { DeleteFacilityReservationAsManagerCommand, CreateFacilityReservationActivityAsManagerCommand, DisableFacilityReservationActivityAsManagerCommand, EnableFacilityReservationActivityAsManagerCommand, Facility, GetFacilityReservationsForFacilityAsManagerQuery, FacilityReservation, SetUpFacilityReservationsForFacilityAsManagerCommand, UpdateTimeBasedRestrictionsOfFacilityReservationActivityAsManagerCommand, UpdateColorOfFacilityReservationActivityAsManagerCommand, UpdateReservationDisplayNameTypeOfFacilityAsManagerCommand, UpdateBookingInAdvanceOfFacilityAsManagerCommand, UpdateBookingNoticeOfFacilityAsManagerCommand, UpdateMaxUsageOfFacilityAsManagerCommand, UpdateMinDistanceToOwnBookingsWithDifferentHorsesIntervalsOfFacilityAsManagerCommand, UpdateTimeOptionIntervalOfFacilityAsManagerCommand, UpdateNameOfFacilityReservationActivityAsManagerCommand, UpdateOrderOfFacilityReservationActivitiesOfFacilityAsManagerCommand, UpdateRequiredSpacesOfFacilityReservationActivityAsManagerCommand, UpdateTimeOptionIntervalRangeOfFacilityReservationActivityAsManagerCommand, AllowParallelReserverationInOtherFacilitiesForFacilityAsManagerCommand, ForbidParallelReserverationInOtherFacilitiesForFacilityAsManagerCommand, UpdateIsRiderAbleToReserveMultipleParallelActivitiesOfFacilityReservationActivityAsManagerCommand, EvaluationFilterData, GetEvaluationByUserAsManagerQuery, EvaluationByUser, EvaluationByHorseOwner, GetEvaluationByHorseOwnerAsManagerQuery } from './types';

interface FacilityReservationsManagementState {
  currentFacilityId: string | null;
  facilities: Facility[];
  facilityReservations: FacilityReservation[];
  currentFacilityReservationsLimit: number;
  currentFacilityReservationsTotalCount: number;
  evaluationFilterData: EvaluationFilterData | null;
  timeFrameForEvaluationByUser: MomentTimeFrame | null;
  idOfUserForEvaluationByUser: UserId | null;
  idOfHorseForEvaluationByUser: HorseId | null;
  idsOfFacilityReservationActivityForEvaluationByUser: FacilityReservationActivityId[];
  timeFrameForEvaluationByHorseOwner: MomentTimeFrame | null;
  idOfHorseOwnerForEvaluationByHorseOwner: UserId | null;
  idOfHorseForEvaluationByHorseOwner: HorseId | null;
  idsOfFacilityReservationActivityForEvaluationByHorseOwner: FacilityReservationActivityId[];
  evaluationByUser: EvaluationByUser | null;
  evaluationByHorseOwner: EvaluationByHorseOwner | null;

  loadFacilitiesStatus: ActionStatus;
  getFacilityReservationsForFacilityStatus: ActionStatus;
  setUpFacilityReservationsForFacilityStatus: ActionStatus;
  updateBookingInAdvanceOfFacilityStatus: ActionStatus;
  updateTimeOptionIntervalOfFacilityStatus: ActionStatus;
  updateReservationDisplayNameTypeOfFacilityStatus: ActionStatus;
  updateBookingNoticeOfFacilityStatus: ActionStatus;
  updateMaxUsageOfFacilityStatus: ActionStatus;
  updateMinDistanceToOwnBookingsWithDifferentHorsesIntervalsOfFacilityStatus: ActionStatus;
  createFacilityReservationActivityStatus: ActionStatus;
  updateNameOfFacilityReservationActivityStatus: ActionStatus;
  updateTimeOptionIntervalRangeOfFacilityReservationActivityStatus: ActionStatus;
  updateRequiredSpacesOfFacilityReservationActivityStatus: ActionStatus;
  updateColorOfFacilityReservationActivityStatus: ActionStatus;
  updateTimeBasedRestrictionsOfFacilityReservationActivityStatus: ActionStatus;
  enableFacilityReservationActivityStatus: ActionStatus;
  disableFacilityReservationActivityStatus: ActionStatus;
  updateOrderOfFacilityReservationActivitiesOfFacilityStatus: ActionStatus;
  deleteFacilityReservationStatus: ActionStatus;
  allowParallelReserverationInOtherFacilitiesForFacilityStatus: ActionStatus;
  forbidParallelReserverationInOtherFacilitiesForFacilityStatus: ActionStatus;
  updateIsRiderAbleToReserveMultipleParallelActivitiesOfFacilityReservationActivityStatus: ActionStatus;
  getEvaluationFilterDataStatus: ActionStatus;
  getEvaluationByUserStatus: ActionStatus;
  getEvaluationByHorseOwnerStatus: ActionStatus;
}

function initialState(): FacilityReservationsManagementState {
  return {
    currentFacilityId: null,
    facilities: [],
    facilityReservations: [],
    currentFacilityReservationsLimit: 100,
    currentFacilityReservationsTotalCount: 0,
    evaluationFilterData: null,
    timeFrameForEvaluationByUser: null,
    idOfUserForEvaluationByUser: null,
    idOfHorseForEvaluationByUser: null,
    idsOfFacilityReservationActivityForEvaluationByUser: [],
    timeFrameForEvaluationByHorseOwner: null,
    idOfHorseOwnerForEvaluationByHorseOwner: null,
    idOfHorseForEvaluationByHorseOwner: null,
    idsOfFacilityReservationActivityForEvaluationByHorseOwner: [],
    evaluationByUser: null,
    evaluationByHorseOwner: null,

    loadFacilitiesStatus: ActionStatus.None,
    getFacilityReservationsForFacilityStatus: ActionStatus.None,
    setUpFacilityReservationsForFacilityStatus: ActionStatus.None,
    updateBookingInAdvanceOfFacilityStatus: ActionStatus.None,
    updateTimeOptionIntervalOfFacilityStatus: ActionStatus.None,
    updateReservationDisplayNameTypeOfFacilityStatus: ActionStatus.None,
    updateBookingNoticeOfFacilityStatus: ActionStatus.None,
    updateMaxUsageOfFacilityStatus: ActionStatus.None,
    updateMinDistanceToOwnBookingsWithDifferentHorsesIntervalsOfFacilityStatus: ActionStatus.None,
    createFacilityReservationActivityStatus: ActionStatus.None,
    updateNameOfFacilityReservationActivityStatus: ActionStatus.None,
    updateTimeOptionIntervalRangeOfFacilityReservationActivityStatus: ActionStatus.None,
    updateRequiredSpacesOfFacilityReservationActivityStatus: ActionStatus.None,
    updateColorOfFacilityReservationActivityStatus: ActionStatus.None,
    updateTimeBasedRestrictionsOfFacilityReservationActivityStatus: ActionStatus.None,
    enableFacilityReservationActivityStatus: ActionStatus.None,
    disableFacilityReservationActivityStatus: ActionStatus.None,
    updateOrderOfFacilityReservationActivitiesOfFacilityStatus: ActionStatus.None,
    deleteFacilityReservationStatus: ActionStatus.None,
    allowParallelReserverationInOtherFacilitiesForFacilityStatus: ActionStatus.None,
    forbidParallelReserverationInOtherFacilitiesForFacilityStatus: ActionStatus.None,
    updateIsRiderAbleToReserveMultipleParallelActivitiesOfFacilityReservationActivityStatus: ActionStatus.None,
    getEvaluationFilterDataStatus: ActionStatus.None,
    getEvaluationByUserStatus: ActionStatus.None,
    getEvaluationByHorseOwnerStatus: ActionStatus.None,
  };
}

export const useFacilityReservationsManagementStore = defineStore('facilityReservationsManagement', {
  state: (): FacilityReservationsManagementState => initialState(),
  getters: {
    currentFacility: (state: FacilityReservationsManagementState): Facility | null => state.currentFacilityId !== null
      ? state.facilities.find((facility) => facility.facilityId === state.currentFacilityId) || null
      : null,

    isLoadFacilitiesProcessing: (state: FacilityReservationsManagementState): boolean =>
      state.loadFacilitiesStatus === ActionStatus.InProgress,
    isGetFacilityReservationsForFacilityProcessing: (state: FacilityReservationsManagementState): boolean =>
      state.getFacilityReservationsForFacilityStatus === ActionStatus.InProgress,
    isGetEvaluationFilterDataProcessing: (state: FacilityReservationsManagementState): boolean =>
      state.getEvaluationFilterDataStatus === ActionStatus.InProgress,
    isGetEvaluationByUserProcessing: (state: FacilityReservationsManagementState): boolean =>
      state.getEvaluationByUserStatus === ActionStatus.InProgress,
    isGetEvaluationByHorseOwnerProcessing: (state: FacilityReservationsManagementState): boolean =>
      state.getEvaluationByHorseOwnerStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateCurrentFacility(facilityId: string): Promise<void> {
      this.currentFacilityId = facilityId;
      return Promise.resolve();
    },

    resetCurrentFacility(): Promise<void> {
      this.currentFacilityId = null;

      return Promise.resolve();
    },

    increaseFacilityReservationsLimit(): Promise<void> {
      this.currentFacilityReservationsLimit += 100;
      const query: GetFacilityReservationsForFacilityAsManagerQuery = {
        facilityId: this.currentFacilityId!,
        limit: this.currentFacilityReservationsLimit,
        offset: 0,
      };

      return this.getFacilityReservationsForFacility(query);
    },

    setTimeFrameForEvaluationForUser(timeFrameForEvaluation: MomentTimeFrame): Promise<void> {
      this.timeFrameForEvaluationByUser = timeFrameForEvaluation;

      return this.getEvaluationByUser();
    },

    setUserForEvaluationForUser(idOfUserForEvaluation: UserId | null): Promise<void> {
      this.idOfUserForEvaluationByUser = idOfUserForEvaluation;

      return this.getEvaluationByUser();
    },

    setHorseForEvaluationByUser(idOfHorseForEvaluation: HorseId | null): Promise<void> {
      this.idOfHorseForEvaluationByUser = idOfHorseForEvaluation;

      return this.getEvaluationByUser();
    },

    setFacilityReservationActivitiesForEvaluationByUser(
      idsOfFacilityReservationActivityForEvaluation: FacilityReservationActivityId[]
    ): Promise<void> {
      this.idsOfFacilityReservationActivityForEvaluationByUser = idsOfFacilityReservationActivityForEvaluation;

      return this.getEvaluationByUser();
    },

    setTimeFrameForEvaluationForHorseOwner(timeFrameForEvaluation: MomentTimeFrame): Promise<void> {
      this.timeFrameForEvaluationByHorseOwner = timeFrameForEvaluation;

      return this.getEvaluationByHorseOwner();
    },

    setHorseOwnerForEvaluationByHorseOwner(idOfHorseOwnerForEvaluation: UserId | null): Promise<void> {
      this.idOfHorseOwnerForEvaluationByHorseOwner = idOfHorseOwnerForEvaluation;

      return this.getEvaluationByHorseOwner();
    },

    setHorseForEvaluationByHorseOwner(idOfHorseForEvaluation: HorseId | null): Promise<void> {
      this.idOfHorseForEvaluationByHorseOwner = idOfHorseForEvaluation;

      return this.getEvaluationByHorseOwner();
    },

    setFacilityReservationActivitiesForEvaluationByHorseOwner(
      idsOfFacilityReservationActivityForEvaluation: FacilityReservationActivityId[]
    ): Promise<void> {
      this.idsOfFacilityReservationActivityForEvaluationByHorseOwner = idsOfFacilityReservationActivityForEvaluation;

      return this.getEvaluationByHorseOwner();
    },

    // -- Queries

    getFacilitiesOverview(): Promise<void> {
      const { loadFacilitiesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        loadFacilitiesStatus,
        () => loadFacilities(attachFarmAndUserProperties({}))
          .then(async (facilities) => {
            this.facilities = facilities;
          })
      );
    },

    getFacilityReservationsForFacility(query: GetFacilityReservationsForFacilityAsManagerQuery): Promise<void> {
      const { getFacilityReservationsForFacilityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getFacilityReservationsForFacilityStatus,
        () => getFacilityReservationsForFacility(attachFarmAndUserProperties(query))
          .then(async (response) => {
            this.facilityReservations = response.facilityReservations;
            this.currentFacilityReservationsTotalCount = response.totalCount;
          })
      );
    },

    getEvaluationFilterData(): Promise<void> {
      const { getEvaluationFilterDataStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getEvaluationFilterDataStatus,
        () => getEvaluationFilterData(attachFarmAndUserProperties({}))
          .then(async (filterData) => {
            this.evaluationFilterData = filterData;
          })
      );
    },

    getEvaluationByUser(): Promise<void> {
      const query: GetEvaluationByUserAsManagerQuery = {
        timeFrame: this.timeFrameForEvaluationByUser!,
        targetUserId: this.idOfUserForEvaluationByUser,
        horseId: this.idOfHorseForEvaluationByUser,
        facilityReservationActivityIds: this.idsOfFacilityReservationActivityForEvaluationByUser,
      };
      const { getEvaluationByUserStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getEvaluationByUserStatus,
        () => getEvaluationByUser(attachFarmAndUserProperties(query))
          .then(async (evaluationByUser) => {
            this.evaluationByUser = evaluationByUser;
          })
      );
    },

    getEvaluationByHorseOwner(): Promise<void> {
      const query: GetEvaluationByHorseOwnerAsManagerQuery = {
        timeFrame: this.timeFrameForEvaluationByHorseOwner!,
        idOfHorseOwner: this.idOfHorseOwnerForEvaluationByHorseOwner,
        horseId: this.idOfHorseForEvaluationByHorseOwner,
        facilityReservationActivityIds: this.idsOfFacilityReservationActivityForEvaluationByHorseOwner,
      };
      const { getEvaluationByHorseOwnerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getEvaluationByHorseOwnerStatus,
        () => getEvaluationByHorseOwner(attachFarmAndUserProperties(query))
          .then(async (evaluationByHorseOwner) => {
            this.evaluationByHorseOwner = evaluationByHorseOwner;
          })
      );
    },

    // -- Commands

    setUpFacilityReservationsForFacility(command: SetUpFacilityReservationsForFacilityAsManagerCommand): Promise<void> {
      const { setUpFacilityReservationsForFacilityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        setUpFacilityReservationsForFacilityStatus,
        () => setUpFacilityReservationsForFacility(attachFarmAndUserProperties(command))
          .then(() => this.getFacilitiesOverview())
      );
    },

    updateTimeOptionIntervalOfFacility(command: UpdateTimeOptionIntervalOfFacilityAsManagerCommand): Promise<void> {
      const { updateTimeOptionIntervalOfFacilityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateTimeOptionIntervalOfFacilityStatus,
        () => updateTimeOptionIntervalOfFacility(attachFarmAndUserProperties(command))
          .then(() => this.getFacilitiesOverview())
      );
    },

    updateBookingInAdvanceOfFacility(command: UpdateBookingInAdvanceOfFacilityAsManagerCommand): Promise<void> {
      const { updateBookingInAdvanceOfFacilityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateBookingInAdvanceOfFacilityStatus,
        () => updateBookingInAdvanceOfFacility(attachFarmAndUserProperties(command))
          .then(() => this.getFacilitiesOverview())
      );
    },

    updateMaxUsageOfFacility(command: UpdateMaxUsageOfFacilityAsManagerCommand): Promise<void> {
      const { updateMaxUsageOfFacilityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateMaxUsageOfFacilityStatus,
        () => updateMaxUsageOfFacility(attachFarmAndUserProperties(command))
          .then(() => this.getFacilitiesOverview())
      );
    },

    updateMinDistanceToOwnBookingsWithDifferentHorsesIntervalsOfFacility(
      command: UpdateMinDistanceToOwnBookingsWithDifferentHorsesIntervalsOfFacilityAsManagerCommand
    ): Promise<void> {
      const { updateMinDistanceToOwnBookingsWithDifferentHorsesIntervalsOfFacilityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateMinDistanceToOwnBookingsWithDifferentHorsesIntervalsOfFacilityStatus,
        () => updateMinDistanceToOwnBookingsWithDifferentHorsesIntervalsOfFacility(attachFarmAndUserProperties(command))
          .then(() => this.getFacilitiesOverview())
      );
    },

    updateReservationDisplayNameTypeOfFacility(command: UpdateReservationDisplayNameTypeOfFacilityAsManagerCommand): Promise<void> {
      const { updateReservationDisplayNameTypeOfFacilityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateReservationDisplayNameTypeOfFacilityStatus,
        () => updateReservationDisplayNameTypeOfFacility(attachFarmAndUserProperties(command))
          .then(() => this.getFacilitiesOverview())
      );
    },

    updateBookingNoticeOfFacility(command: UpdateBookingNoticeOfFacilityAsManagerCommand): Promise<void> {
      const { updateBookingNoticeOfFacilityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateBookingNoticeOfFacilityStatus,
        () => updateBookingNoticeOfFacility(attachFarmAndUserProperties(command))
          .then(() => this.getFacilitiesOverview())
      );
    },

    createFacilityReservationActivity(command: CreateFacilityReservationActivityAsManagerCommand): Promise<void> {
      const { createFacilityReservationActivityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createFacilityReservationActivityStatus,
        () => createFacilityReservationActivity(attachFarmAndUserProperties(command))
          .then(() => this.getFacilitiesOverview())
          .then(() => useAuthenticationStore().getAuthentication())
      );
    },

    updateNameOfFacilityReservationActivity(command: UpdateNameOfFacilityReservationActivityAsManagerCommand): Promise<void> {
      const { updateNameOfFacilityReservationActivityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateNameOfFacilityReservationActivityStatus,
        () => updateNameOfFacilityReservationActivity(attachFarmAndUserProperties(command))
          .then(() => this.getFacilitiesOverview())
      );
    },

    updateTimeOptionIntervalRangeOfFacilityReservationActivity(
      command: UpdateTimeOptionIntervalRangeOfFacilityReservationActivityAsManagerCommand
    ): Promise<void> {
      const { updateTimeOptionIntervalRangeOfFacilityReservationActivityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateTimeOptionIntervalRangeOfFacilityReservationActivityStatus,
        () => updateTimeOptionIntervalRangeOfFacilityReservationActivity(attachFarmAndUserProperties(command))
          .then(() => this.getFacilitiesOverview())
      );
    },

    updateTimeBasedRestrictionsOfFacilityReservationActivity(
      command: UpdateTimeBasedRestrictionsOfFacilityReservationActivityAsManagerCommand
    ): Promise<void> {
      const { updateTimeBasedRestrictionsOfFacilityReservationActivityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateTimeBasedRestrictionsOfFacilityReservationActivityStatus,
        () => updateTimeBasedRestrictionsOfFacilityReservationActivity(attachFarmAndUserProperties(command))
          .then(() => this.getFacilitiesOverview())
      );
    },

    updateIsRiderAbleToReserveMultipleParallelActivitiesOfFacilityReservationActivity(
      command: UpdateIsRiderAbleToReserveMultipleParallelActivitiesOfFacilityReservationActivityAsManagerCommand
    ): Promise<void> {
      const { updateIsRiderAbleToReserveMultipleParallelActivitiesOfFacilityReservationActivityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateIsRiderAbleToReserveMultipleParallelActivitiesOfFacilityReservationActivityStatus,
        () => updateIsRiderAbleToReserveMultipleParallelActivitiesOfFacilityReservationActivity(attachFarmAndUserProperties(command))
          .then(() => this.getFacilitiesOverview())
      );
    },

    updateColorOfFacilityReservationActivity(command: UpdateColorOfFacilityReservationActivityAsManagerCommand): Promise<void> {
      const { updateColorOfFacilityReservationActivityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateColorOfFacilityReservationActivityStatus,
        () => updateColorOfFacilityReservationActivity(attachFarmAndUserProperties(command))
          .then(() => this.getFacilitiesOverview())
      );
    },

    updateRequiredSpacesOfFacilityReservationActivity(
      command: UpdateRequiredSpacesOfFacilityReservationActivityAsManagerCommand
    ): Promise<void> {
      const { updateRequiredSpacesOfFacilityReservationActivityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateRequiredSpacesOfFacilityReservationActivityStatus,
        () => updateRequiredSpacesOfFacilityReservationActivity(attachFarmAndUserProperties(command))
          .then(() => this.getFacilitiesOverview())
      );
    },

    enableFacilityReservationActivity(command: EnableFacilityReservationActivityAsManagerCommand): Promise<void> {
      const { enableFacilityReservationActivityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        enableFacilityReservationActivityStatus,
        () => enableFacilityReservationActivity(attachFarmAndUserProperties(command))
          .then(() => this.getFacilitiesOverview())
          .then(() => useAuthenticationStore().getAuthentication())
      );
    },

    disableFacilityReservationActivity(command: DisableFacilityReservationActivityAsManagerCommand): Promise<void> {
      const { disableFacilityReservationActivityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        disableFacilityReservationActivityStatus,
        () => disableFacilityReservationActivity(attachFarmAndUserProperties(command))
          .then(() => this.getFacilitiesOverview())
          .then(() => useAuthenticationStore().getAuthentication())
      );
    },

    updateOrderOfFacilityReservationActivitiesOfFacility(
      command: UpdateOrderOfFacilityReservationActivitiesOfFacilityAsManagerCommand
    ): Promise<void> {
      const { updateOrderOfFacilityReservationActivitiesOfFacilityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateOrderOfFacilityReservationActivitiesOfFacilityStatus,
        () => updateOrderOfFacilityReservationActivitiesOfFacility(attachFarmAndUserProperties(command))
          .then(() => this.getFacilitiesOverview())
      );
    },

    cancelFacilityReservation(command: DeleteFacilityReservationAsManagerCommand): Promise<void> {
      const { deleteFacilityReservationStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteFacilityReservationStatus,
        () => deleteFacilityReservation(attachFarmAndUserProperties(command))
          .then(() => {
            const query: GetFacilityReservationsForFacilityAsManagerQuery = {
              facilityId: this.currentFacilityId!,
              limit: this.currentFacilityReservationsLimit,
              offset: 0,
            };
            return this.getFacilityReservationsForFacility(query);
          })
      );
    },

    allowParallelReserverationInOtherFacilitiesForFacility(
      command: AllowParallelReserverationInOtherFacilitiesForFacilityAsManagerCommand
    ): Promise<void> {
      const { allowParallelReserverationInOtherFacilitiesForFacilityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        allowParallelReserverationInOtherFacilitiesForFacilityStatus,
        () => allowParallelReserverationInOtherFacilitiesForFacility(attachFarmAndUserProperties(command))
          .then(() => this.getFacilitiesOverview())
      );
    },

    forbidParallelReserverationInOtherFacilitiesForFacility(
      command: ForbidParallelReserverationInOtherFacilitiesForFacilityAsManagerCommand
    ): Promise<void> {
      const { forbidParallelReserverationInOtherFacilitiesForFacilityStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        forbidParallelReserverationInOtherFacilitiesForFacilityStatus,
        () => forbidParallelReserverationInOtherFacilitiesForFacility(attachFarmAndUserProperties(command))
          .then(() => this.getFacilitiesOverview())
      );
    },

  },
});
