import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { apiUrl, performApiRequest } from '@/infrastructure';
import { RegisterPersonForRidingLessonAsManager, RegisterPersonForRidingLessonSeriesAsManager } from './types';

// -- Commands

export function registerPersonForRidingLessonAsManager(
  query: RegisterPersonForRidingLessonAsManager.RegisterPersonForRidingLessonAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/riding-lessons/register-person-for-riding-lesson-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function registerPersonForRidingLessonSeriesAsManager(
  query: RegisterPersonForRidingLessonSeriesAsManager.RegisterPersonForRidingLessonSeriesAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/riding-lessons/register-person-for-riding-lesson-series-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}
