import { render, staticRenderFns } from "./important-news-dialog.vue?vue&type=template&id=36dd802b&scoped=true&"
import script from "./important-news-dialog.vue?vue&type=script&setup=true&lang=ts&"
export * from "./important-news-dialog.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./important-news-dialog.vue?vue&type=style&index=0&id=36dd802b&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36dd802b",
  null
  
)

export default component.exports