import { PersonId, Name, Address, PhoneNumber, Language, InvoiceAddressForPerson, OrganizationId, InvoiceAddressForOrganization, Link, OrganizationName, PersonIdList, CustomerType, CustomFieldType, CustomFieldId, Options, CustomFieldValues, OrganizationChangeHistory, PersonChangeHistory, Date, Price, InvoiceId, InvoiceNumber, EmailAddress, NameOrderType, Salutation, Country, ArchivedStatus, PersonDocumentId, Moment, CustomFieldValue, OptionId, CustomFieldLabel } from '@/types';
import { Command, CommandWithFiles, Query } from '@/application/types';

export type PersonResponse = {
  persons: PersonForList[];
  totalCount: number;
}

export type PersonForList = {
  personId: PersonId;
  orderedName: string;
  name: Name;
  emailAddress: EmailAddress | null;
  address: Address | null;
  invoiceAddress: InvoiceAddressForPerson | null;
  assignedOrganizations: AssignedOrganization[];
  mobilePhoneNumber: PhoneNumber | null;
  landlinePhoneNumber: PhoneNumber | null;
  language: Language;
  customFields: CustomFieldValues | null;
  comment: string | null;
  changeHistory: PersonChangeHistory;
  isConnectedToUser: boolean;
  isArchived: boolean;
}

export type Person = {
  personId: PersonId;
  salutation: string | null;
  fullName: string;
  name: Name;
  emailAddress: EmailAddress | null;
  userEmailAddress: EmailAddress | null;
  address: Address | null;
  invoiceAddress: InvoiceAddressForPerson | null;
  assignedOrganizations: AssignedOrganization[];
  mobilePhoneNumber: PhoneNumber | null;
  landlinePhoneNumber: PhoneNumber | null;
  language: Language;
  customFields: CustomFieldValues | null;
  comment: string | null;
  changeHistory: PersonChangeHistory;
  isConnectedToUser: boolean;
  isArchived: boolean;
  reasonForArchiving: string | null;
}

export type AssignedOrganization = {
  organizationId: OrganizationId;
  name: OrganizationName;
}

export type OrganizationResponse = {
  organizations: OrganizationForList[];
  totalCount: number;
}

export interface OrganizationForList {
  organizationId: OrganizationId;
  name: string;
  idsOfAssignedPersons: PersonIdList;
  address: Address | null;
  invoiceAddress: InvoiceAddressForOrganization | null;
  mobilePhoneNumber: PhoneNumber | null;
  landlinePhoneNumber: PhoneNumber | null;
  language: Language;
  website: Link | null;
  customFields: CustomFieldValues | null;
  comment: string | null;
  changeHistory: OrganizationChangeHistory;
  isArchived: boolean;
}

export interface Organization {
  organizationId: OrganizationId;
  name: string;
  idsOfAssignedPersons: PersonIdList;
  assignedPersons: AssignedPerson[];
  address: Address | null;
  invoiceAddress: InvoiceAddressForOrganization | null;
  mobilePhoneNumber: PhoneNumber | null;
  landlinePhoneNumber: PhoneNumber | null;
  language: Language;
  website: Link | null;
  customFields: CustomFieldValues | null;
  comment: string | null;
  changeHistory: OrganizationChangeHistory;
  isArchived: boolean;
  reasonForArchiving: string | null;
}

export type AssignedPerson = {
  personId: PersonId;
  name: Name;
  customFieldValue: CustomFieldValue | null;
  customFieldOptions: Options | null;
}

export interface PersonReference {
  personId: PersonId;
  fullName: string;
  isConnectedToUser: boolean;
}

export interface OrganizationReference {
  organizationId: OrganizationId;
  name: OrganizationName;
}

export interface CustomFields {
  customFieldsForPerson: CustomField[];
  customFieldsForOrganization: CustomField[];
  idOfCustomFieldShownWithAssignedPersons: CustomFieldId | null;
}

export interface CustomField {
  customFieldId: CustomFieldId;
  type: CustomFieldType;
  label: string;
  options: Options | null;
}

export interface Service {
  title: string;
  timeFrameType: TimeFrameType;
  grossSellingPrice: number;
  price: Price | null;
  startingAt: Date;
  endingAt: Date | null;
}

export interface Invoice {
  invoiceId: InvoiceId;
  invoiceNumber: InvoiceNumber;
  invoiceDate: Date;
  totalAmount: number;
}

export enum TimeFrameType {
  ONE_DAY = 'ONE_DAY',
  TIME_FRAME = 'TIME_FRAME',
}

export interface PersonDocument {
  personDocumentId: PersonDocumentId;
  title: string;
  uploadedAt: Moment;
  pdfUrl: string;
}

// -- DTOs

export type PersonsFilter = {
  addressCountry: Country | null;
  invoiceAddressCountry: Country | null;
  language: Language | null;
  customFields: CustomFieldValues | null;
  archivedStatus: ArchivedStatus | null;
  organizationId: OrganizationId | null;
}

export type OrganizationsFilter = {
  addressCountry: Country | null;
  invoiceAddressCountry: Country | null;
  language: Language | null;
  customFields: CustomFieldValues | null;
  archivedStatus: ArchivedStatus | null;
}

// -- Queries

export interface GetPersonsAsManagerQuery extends Query {
  search: string | null;
  nameOrderType: NameOrderType;
  addressCountry: Country | null;
  invoiceAddressCountry: Country | null;
  language: Language | null;
  customFields: CustomFieldValues | null;
  archivedStatus: ArchivedStatus | null;
  organizationId: OrganizationId | null;
  limit: number;
}

export interface GetPersonsAsCSVAsManagerQuery extends Query {
  search: string | null;
  addressCountry: Country | null;
  invoiceAddressCountry: Country | null;
  language: Language | null;
  customFields: CustomFieldValues | null;
  archivedStatus: ArchivedStatus | null;
  organizationId: OrganizationId | null;
}

export interface GetPersonAsManagerQuery extends Query {
  personId: PersonId;
}

export interface GetOrganizationsAsManagerQuery extends Query {
  search: string | null;
  addressCountry: Country | null;
  invoiceAddressCountry: Country | null;
  language: Language | null;
  customFields: CustomFieldValues | null;
  archivedStatus: ArchivedStatus | null;
  limit: number;
}

export interface GetOrganizationsAsCSVAsManagerQuery extends Query {
  search: string | null;
  addressCountry: Country | null;
  invoiceAddressCountry: Country | null;
  language: Language | null;
  customFields: CustomFieldValues | null;
}

export interface GetOrganizationAsManagerQuery extends Query {
  organizationId: OrganizationId;
}

export interface GetServicesForPersonAsManagerQuery extends Query {
  personId: PersonId;
}

export interface GetInvoicesForPersonAsManagerQuery extends Query {
  personId: PersonId;
}

export interface GetInvoicesForOrganizationAsManagerQuery extends Query {
  organizationId: OrganizationId;
}

export interface GetInvoicePDFAsManagerQuery extends Query {
  invoiceId: InvoiceId;
}

export interface GetDocumentsForPersonAsManagerQuery extends Query {
  personId: PersonId;
}

export interface GetDocumentPDFAsManagerQuery extends Query {
  personDocumentId: PersonDocumentId;
}

// -- Commands

export interface CreatePersonAsManagerWorkflow extends Command {
  personId: PersonId;
  salutation: Salutation | null;
  name: Name;
  emailAddress: EmailAddress | null;
  address: Address | null;
  invoiceAddress: InvoiceAddressForPerson | null;
  mobilePhoneNumber: PhoneNumber | null;
  landlinePhoneNumber: PhoneNumber | null;
  language: Language;
  customFields: CustomFieldValues | null;
  comment: string | null;
}

export interface UpdateSalutationOfPersonAsManagerCommand extends Command {
  personId: PersonId;
  salutation: Salutation | null;
}

export interface UpdateNameOfPersonAsManagerWorkflow extends Command {
  personId: PersonId;
  name: Name;
}

export interface UpdateEmailAddressOfPersonAsManagerCommand extends Command {
  personId: PersonId;
  emailAddress: EmailAddress;
}

export interface UpdateAddressOfPersonAsManagerCommand extends Command {
  personId: PersonId;
  address: Address | null;
}

export interface DefineInvoiceAddressOfPersonAsManagerWorkflow extends Command {
  personId: PersonId;
  invoiceAddress: InvoiceAddressForPerson;
}

export interface UpdateInvoiceAddressOfPersonAsManagerWorkflow extends Command {
  personId: PersonId;
  invoiceAddress: InvoiceAddressForPerson;
}

export interface RemoveInvoiceAddressOfPersonAsManagerWorkflow extends Command {
  personId: PersonId;
}

export interface UpdateAdditionalMasterDataOfPersonAsManagerCommand extends Command {
  personId: PersonId;
  landlinePhoneNumber: PhoneNumber | null;
  mobilePhoneNumber: PhoneNumber | null;
  language: Language;
  customFields: CustomFieldValues | null;
  comment: string | null;
}

export interface CreateOrganizationAsManagerWorkflow extends Command {
  organizationId: OrganizationId;
  name: OrganizationName;
  idsOfAssignedPersons: PersonIdList;
  address: Address | null;
  invoiceAddress: InvoiceAddressForOrganization | null;
  landlinePhoneNumber: PhoneNumber | null;
  mobilePhoneNumber: PhoneNumber | null;
  language: Language;
  website: Link | null;
  customFields: CustomFieldValues | null;
  comment: string | null;
}

export interface UpdateNameOfOrganizationAsManagerWorkflow extends Command {
  organizationId: OrganizationId;
  name: OrganizationName;
}

export interface UpdateAddressOfOrganizationAsManagerCommand extends Command {
  organizationId: OrganizationId;
  address: Address | null;
}

export interface DefineInvoiceAddressOfOrganizationAsManagerWorkflow extends Command {
  organizationId: OrganizationId;
  invoiceAddress: InvoiceAddressForOrganization;
}

export interface UpdateInvoiceAddressOfOrganizationAsManagerWorkflow extends Command {
  organizationId: OrganizationId;
  invoiceAddress: InvoiceAddressForOrganization;
}

export interface RemoveInvoiceAddressOfOrganizationAsManagerWorkflow extends Command {
  organizationId: OrganizationId;
}

export interface UpdateAdditionalMasterDataOfOrganizationAsManagerCommand extends Command {
  organizationId: OrganizationId;
  landlinePhoneNumber: PhoneNumber | null;
  mobilePhoneNumber: PhoneNumber | null;
  language: Language;
  website: Link | null;
  customFields: CustomFieldValues | null;
  comment: string | null;
}

export interface UpdateAssignedPersonsOfOrganizationAsManagerWorkflow extends Command {
  organizationId: OrganizationId;
  idsOfAssignedPersons: PersonIdList;
}

export interface CreateCustomFieldAsManagerCommand extends Command {
  customerType: CustomerType;
  type: CustomFieldType;
  label: CustomFieldLabel;
  options: Options | null;
}

export interface UpdateLabelOfCustomFieldAsManagerCommand extends Command {
  customFieldId: CustomFieldId;
  label: CustomFieldLabel;
}

export interface UpdateOptionsOfCustomFieldAsManagerCommand extends Command {
  customFieldId: CustomFieldId;
  options: Options;
}

export interface ArchivePersonAsManagerWorkflow extends Command {
  personId: PersonId;
  fullNameOfPerson: string;
  reason: string | null;
}

export interface ArchiveOrganizationAsManagerWorkflow extends Command {
  organizationId: OrganizationId;
  organizationName: string;
  reason: string | null;
}

export interface CreatePersonDocumentAsManagerCommand extends CommandWithFiles {
  body: {
    personId: PersonId;
    title: string;
  },
  files: {
    document: File;
  };
}

export interface DeletePersonDocumentAsManagerCommand extends Command {
  personDocumentId: PersonDocumentId;
}

export interface UpdateCustomFieldShownWithAssignedPersonsAsManagerCommand extends Command {
  customFieldId: CustomFieldId | null;
}

export interface DeleteCustomFieldAsManagerCommand extends Command {
  customFieldId: CustomFieldId;
}

export interface AssignOptionToPersonsAsManagerCommand extends Command {
  customFieldId: CustomFieldId;
  optionId: OptionId;
  idsOfPersons: PersonIdList;
}
