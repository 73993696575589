import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { apiUrl, performApiRequest } from '@/infrastructure';
import { GetFarmServicesInMonthForHorseAsManager } from './types';

// -- Queries

export function getFarmServicesInMonthForHorseAsManager(
  query: GetFarmServicesInMonthForHorseAsManager.GetFarmServicesInMonthForHorseAsManagerQuery
): Promise<GetFarmServicesInMonthForHorseAsManager.FarmServiceBooking[]> {
  const url = `${apiUrl}/api/farm-services/get-farm-services-in-month-for-horse-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}
