import { RouteConfig } from 'vue-router';
import { RouteAccessibility } from '@/helpers/data';
import { Feature } from '@/types';
import HorseTabs from './components/horse-tabs.vue';
import HorseDetailsLoadingWrapper from './components/horse-details-loading-wrapper.vue';
import HorseShares from './components/horse-shares.vue';
import GerblhofFeeding from './components/gerblhof-feeding.vue';
import FarmServices from './components/farm-services.vue';
import Feeding from './components/feeding.vue';
import HorseShareInviteDecision from './components/horse-share-invite-decision.vue';
import Horses from './components/horses.vue';
import Invoices from './components/invoices.vue';
import MedicalRecordRoot from './components/medical-record-root.vue';
import MedicalRecord from './components/medical-record.vue';
import VaccinationHistory from './components/vaccination-history.vue';
import SettlementTabs from './components/settlement-tabs.vue';
import SettlementForMonth from './components/settlement-for-month.vue';
import SettlementForecast from './components/settlement-forecast.vue';
import TrainingDiaryLoadingWrapper from './components/training-diary-loading-wrapper.vue';
import PenExemptionsLoadingWrapper from './components/pen-exemptions-loading-wrapper.vue';
import HorseWeightHistory from './components/horse-weight-history.vue';
import HorseDrugsHistory from './components/horse-drugs-history.vue';
import HorseFarrierAppointmentsHistory from './components/horse-farrier-appointments-history.vue';
import HorseWormingTreatmentsHistory from './components/horse-worming-treatments-history.vue';
import HorseVeterinarianAppointmentsHistory from './components/horse-veterinarian-appointments-history.vue';

export const MY_STABLE_ROUTE_PREFIX = '/mein-stall';

export const HORSE_DETAILS_ROUTE_NAMES = [
  'horse-details',
  'horse-shares',
  'gerblhof-feeding',
  'feeding',
  'horse-bookings-farm-services',
  'horse-pen-exemptions',
  'horse-medical-record',
  'horse-weight-history',
  'horse-drugs-history',
  'horse-farrier-appointments-history',
  'horse-veterinarian-appointments-history',
  'horse-worming-treatments-history',
  'horse-vaccination-certificate-history',
  'training-diary',
];

export const myStableRoutes: RouteConfig[] = [
  {
    // Warning: Path is used in emails (see ClientURLGenerator)
    path: MY_STABLE_ROUTE_PREFIX,
    component: {
      template: `<div class="my-stable"><router-view /></div>`,
    },
    children: [
      {
        path: '',
        redirect: 'pferde',
      }, {
        path: 'pferde',
        component: {
          template: `<div class="horses"><router-view /></div>`,
        },
        children: [
          {
            // Warning - Used in push notifications
            name: 'my-stable/horses',
            path: '',
            component: Horses,
            meta: {
              title: 'Pferde',
              accessibleFor: RouteAccessibility.AUTHENTICATED,
            },
          },
          {
            path: 'pferd/:horseId',
            component: HorseTabs,
            children: [
              {
                path: '',
                redirect: 'training-diary',
              },
              {
                name: 'training-diary',
                path: 'trainingstagebuch',
                component: TrainingDiaryLoadingWrapper,
                meta: {
                  title: 'Trainingstagebuch',
                  accessibleFor: RouteAccessibility.AUTHENTICATED,
                  routeNameForBackButton: 'my-stable/horses',
                },
              },
              {
                name: 'horse-shares',
                path: 'mitbetreuung',
                component: HorseShares,
                meta: {
                  title: 'Reitbeteiligungen',
                  accessibleFor: RouteAccessibility.AUTHENTICATED,
                  routeNameForBackButton: 'my-stable/horses',
                },
              },
              {
                name: 'gerblhof-feeding',
                path: 'gerblhof-fuetterung',
                component: GerblhofFeeding,
                meta: {
                  title: 'Fütterung',
                  accessibleFor: RouteAccessibility.AUTHENTICATED,
                  requiresFeature: Feature.LEDGER,
                  routeNameForBackButton: 'my-stable/horses',
                },
              },
              {
                name: 'feeding',
                path: 'fuetterung',
                component: Feeding,
                meta: {
                  title: 'Fütterung',
                  accessibleFor: RouteAccessibility.AUTHENTICATED,
                  requiresFeature: Feature.FEEDING,
                  routeNameForBackButton: 'my-stable/horses',
                },
              },
              {
                name: 'horse-bookings-farm-services',
                path: 'hofdienste',
                component: FarmServices,
                meta: {
                  title: 'Hofdienste',
                  accessibleFor: RouteAccessibility.AUTHENTICATED,
                  requiresFeature: Feature.LEDGER,
                  routeNameForBackButton: 'my-stable/horses',
                },
              },
              {
                name: 'horse-pen-exemptions',
                path: 'koppelausnahmen',
                component: PenExemptionsLoadingWrapper,
                meta: {
                  title: 'Koppelausnahmen',
                  accessibleFor: RouteAccessibility.AUTHENTICATED,
                  requiresFeature: Feature.PEN,
                  routeNameForBackButton: 'my-stable/horses',
                },
              },
              {
                path: 'gesundheitsakte',
                component: MedicalRecordRoot,
                meta: {
                  title: 'Gesundheitsakte',
                  accessibleFor: RouteAccessibility.AUTHENTICATED,
                  routeNameForBackButton: 'my-stable/horses',
                },
                children: [
                  {
                    name: 'horse-medical-record',
                    path: '',
                    component: MedicalRecord,
                    meta: {
                      title: 'Gesundheitsakte',
                      accessibleFor: RouteAccessibility.AUTHENTICATED,
                      routeNameForBackButton: 'my-stable/horses',
                    },
                  },
                  {
                    name: 'horse-weight-history',
                    path: 'gewichtshistorie',
                    component: HorseWeightHistory,
                    meta: {
                      title: 'Gewichtshistorie',
                      accessibleFor: RouteAccessibility.AUTHENTICATED,
                      routeNameForBackButton: 'horse-medical-record',
                    },
                  },
                  {
                    name: 'horse-drugs-history',
                    path: 'medikamentenhistorie',
                    component: HorseDrugsHistory,
                    meta: {
                      title: 'Medikamenten-Historie',
                      accessibleFor: RouteAccessibility.AUTHENTICATED,
                      routeNameForBackButton: 'horse-medical-record',
                    },
                  },
                  {
                    name: 'horse-farrier-appointments-history',
                    path: 'hufschmied-historie',
                    component: HorseFarrierAppointmentsHistory,
                    meta: {
                      title: 'Hufschmied-Historie',
                      accessibleFor: RouteAccessibility.AUTHENTICATED,
                      routeNameForBackButton: 'horse-medical-record',
                    },
                  },
                  {
                    name: 'horse-veterinarian-appointments-history',
                    path: 'tierarzt-historie',
                    component: HorseVeterinarianAppointmentsHistory,
                    meta: {
                      title: 'Tierarzt-Historie',
                      accessibleFor: RouteAccessibility.AUTHENTICATED,
                      routeNameForBackButton: 'horse-medical-record',
                    },
                  },
                  {
                    name: 'horse-worming-treatments-history',
                    path: 'wurmkur-historie',
                    component: HorseWormingTreatmentsHistory,
                    meta: {
                      title: 'Wurmkur-Historie',
                      accessibleFor: RouteAccessibility.AUTHENTICATED,
                      routeNameForBackButton: 'horse-medical-record',
                    },
                  },
                  {
                    name: 'horse-vaccination-certificate-history',
                    path: 'impfhistorie/:illnessId',
                    component: VaccinationHistory,
                    meta: {
                      title: 'Historie',
                      accessibleFor: RouteAccessibility.AUTHENTICATED,
                      routeNameForBackButton: 'horse-medical-record',
                    },
                  },
                ],
              },
              {
                name: 'horse-details',
                path: 'details',
                component: HorseDetailsLoadingWrapper,
                meta: {
                  title: 'Details',
                  accessibleFor: RouteAccessibility.AUTHENTICATED,
                  routeNameForBackButton: 'my-stable/horses',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'abrechnung',
        component: SettlementTabs,
        meta: {
          title: 'Abrechnung',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresFeature: Feature.LEDGER,
        },
        children: [
          {
            name: 'my-stable-settlement-month',
            path: '',
            component: SettlementForMonth,
            meta: {
              title: 'Abrechnung',
              accessibleFor: RouteAccessibility.AUTHENTICATED,
              requiresFeature: Feature.LEDGER,
            },
          },
          {
            name: 'my-stable-settlement-forecast',
            path: 'kostenprognose',
            component: SettlementForecast,
            meta: {
              title: 'Abrechnung',
              accessibleFor: RouteAccessibility.AUTHENTICATED,
              requiresFeature: Feature.LEDGER,
            },
          },
        ],
      },
      {
        // Warning: Path is used in emails (see ClientURLGenerator)
        name: 'my-stable/invoicing',
        path: 'rechnungen',
        component: Invoices,
        meta: {
          title: 'Rechnungen',
          accessibleFor: RouteAccessibility.AUTHENTICATED,
          requiresFeature: Feature.INVOICING,
        },
      },
    ],
  },
  {
    name: 'accept-horse-share-invite',
    path: '/pferde/reitbeteiligungsanfrage/:horseShareInviteId', // Warning: Is used in emails
    component: HorseShareInviteDecision,
    meta: {
      title: 'Einladung annehmen',
      accessibleFor: RouteAccessibility.AUTHENTICATED,
    },
  },
];
