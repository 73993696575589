import { attachFarmAndUserProperties, attachFarmAndUserPropertiesToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { apiUrl, performApiRequest, performApiRequestWithFiles } from '@/infrastructure';
import { GetDocumentsAsManager, CreateDocumentAsManager, UpdateDocumentFileAsManager, UpdateDocumentDescriptionAsManager, DeleteDocumentAsManager } from './types';

// -- Queries

export function getDocumentsAsManager(
  query: GetDocumentsAsManager.GetDocumentsAsManagerQuery
): Promise<GetDocumentsAsManager.FarmDocument[]> {
  const url = `${apiUrl}/api/farm-documents/get-documents-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

// -- Commands

export function createDocumentAsManager(
  query: CreateDocumentAsManager.CreateDocumentAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/farm-documents/create-document-as-manager-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: attachFarmAndUserPropertiesToCommandWithFiles(query) });
}

export function updateDocumentFileAsManager(
  query: UpdateDocumentFileAsManager.UpdateDocumentFileAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/farm-documents/update-document-file-as-manager-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: attachFarmAndUserPropertiesToCommandWithFiles(query) });
}

export function updateDocumentDescriptionAsManager(
  query: UpdateDocumentDescriptionAsManager.UpdateDocumentDescriptionAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/farm-documents/update-document-description-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function deleteDocumentAsManager(
  query: DeleteDocumentAsManager.DeleteDocumentAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/farm-documents/delete-document-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}
