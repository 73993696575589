import { defineStore, storeToRefs } from 'pinia';
import { ActionStatus } from '@/application/types';
import { wrapAction, wrapActionWithProgress } from '@/store';
import { createDocumentAsManager, CreateDocumentAsManager, deleteDocumentAsManager, DeleteDocumentAsManager, getDocumentsAsManager, GetDocumentsAsManager, updateDocumentDescriptionAsManager, UpdateDocumentDescriptionAsManager, updateDocumentFileAsManager, UpdateDocumentFileAsManager } from '@/feature/farm-documents';
import { APIResource, initialAPIResource } from '@/infrastructure';

interface FarmDocumentsManagementState {
  farmDocuments: APIResource<GetDocumentsAsManager.FarmDocument[]>;

  createDocumentAsManagerStatus: ActionStatus;
  updateDocumentFileAsManagerStatus: ActionStatus;
  updateDocumentDescriptionAsManagerStatus: ActionStatus;
  deleteDocumentAsManagerStatus: ActionStatus;
}

function initialState(): FarmDocumentsManagementState {
  return {
    farmDocuments: initialAPIResource(),

    createDocumentAsManagerStatus: ActionStatus.None,
    updateDocumentFileAsManagerStatus: ActionStatus.None,
    updateDocumentDescriptionAsManagerStatus: ActionStatus.None,
    deleteDocumentAsManagerStatus: ActionStatus.None,
  };
}

export const useFarmDocumentsManagementStore = defineStore('manageFarmDocuments', {
  state: (): FarmDocumentsManagementState => initialState(),
  actions: {

    // -- Queries

    getDocumentsAsManager(): Promise<void> {
      const { farmDocuments } = storeToRefs(this);
      return wrapAction(
        farmDocuments,
        () => getDocumentsAsManager({})
      );
    },

    // -- Commands

    createDocumentAsManager(command: CreateDocumentAsManager.CreateDocumentAsManagerCommand): Promise<void> {
      const { createDocumentAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createDocumentAsManagerStatus,
        () => createDocumentAsManager(command)
          .then(() => this.getDocumentsAsManager())
      );
    },

    updateDocumentFileAsManager(command: UpdateDocumentFileAsManager.UpdateDocumentFileAsManagerCommand): Promise<void> {
      const { updateDocumentFileAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateDocumentFileAsManagerStatus,
        () => updateDocumentFileAsManager(command)
          .then(() => this.getDocumentsAsManager())
      );
    },

    updateDocumentDescriptionAsManager(
      command: UpdateDocumentDescriptionAsManager.UpdateDocumentDescriptionAsManagerCommand
    ): Promise<void> {
      const { updateDocumentDescriptionAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateDocumentDescriptionAsManagerStatus,
        () => updateDocumentDescriptionAsManager(command)
          .then(() => this.getDocumentsAsManager())
      );
    },

    deleteDocumentAsManager(command: DeleteDocumentAsManager.DeleteDocumentAsManagerCommand): Promise<void> {
      const { deleteDocumentAsManagerStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteDocumentAsManagerStatus,
        () => deleteDocumentAsManager(command)
          .then(() => this.getDocumentsAsManager())
      );
    },

  },
});
