import { render, staticRenderFns } from "./paddock-status-history-table.vue?vue&type=template&id=52800067&scoped=true&"
import script from "./paddock-status-history-table.vue?vue&type=script&setup=true&lang=ts&"
export * from "./paddock-status-history-table.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./paddock-status-history-table.vue?vue&type=style&index=0&id=52800067&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52800067",
  null
  
)

export default component.exports