import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { apiUrl, performApiRequest } from '@/infrastructure';
import { GetCustomBookingsForHorseAsManager, GetCreditNotesForHorseAsManager } from './types';

// -- Queries

export function getCustomBookingsForHorseAsManager(
  query: GetCustomBookingsForHorseAsManager.GetCustomBookingsForHorseAsManagerQuery
): Promise<GetCustomBookingsForHorseAsManager.Response> {
  const url = `${apiUrl}/api/settlement/get-custom-bookings-for-horse-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getCreditNotesForHorseAsManager(
  query: GetCreditNotesForHorseAsManager.GetCreditNotesForHorseAsManagerQuery
): Promise<GetCreditNotesForHorseAsManager.Response> {
  const url = `${apiUrl}/api/settlement/get-credit-notes-for-horse-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}
