import { render, staticRenderFns } from "./update-reservation-display-name-type-of-facility-dialog.vue?vue&type=template&id=4a5aad63&"
import script from "./update-reservation-display-name-type-of-facility-dialog.vue?vue&type=script&setup=true&lang=ts&"
export * from "./update-reservation-display-name-type-of-facility-dialog.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports