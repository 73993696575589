import { Command, CommandWithFiles, Query } from '@/application/types';
import { Date, FacilityReservationId, FarmServiceBookingId, FarmServiceId, FarmServiceIdList, FeedingConfiguration, FeedingSettingId, FeedingSettingsHistoryEntryId, FeedingSettingsHistoryEntryType, FeedTypeId, FeedUnit, HorseDrugEntryId, HorseFarrierAppointmentId, HorseId, HorseTrainingSessionId, HorseVeterinarianAppointmentId, HorseWeightEntryId, HorseWormingTreatmentId, IllnessId, InvoiceAddressForPerson, InvoiceId, Link, Moment, MomentTimeFrame, Month, MoveHorseTo, PenExemptionId, PersonId, PhoneNumber, Strain, Time, TimeFrame, UserId, VaccinationId, VaccinationStatus, VaccinationWaitPeriod, VaccineId, FarmServiceBookingType, PaymentMethod, ConcentratedFeedId, FarmServiceSubscriptionConfiguration, FarmServiceOneOffConfiguration, FeedingUpdateId } from '@/types';
import { BlurHashImageReadModel } from '@/helpers';

export interface UserIdentification {
  id: UserId;
  name: string;
}

export interface HorseIdentification {
  id: HorseId;
  name: string;
}

export interface VaccinationCertificate {
  illnesses: Illness[];
  vaccines: Vaccine[];
  illnessesWithStatus: IllnessWithStatus[];
}

export interface IllnessWithStatus {
  illness: Illness;
  status: VaccinationStatus;
  history: Vaccination[];
}

export interface Illness {
  illnessId: IllnessId;
  name: string;
}

export interface Vaccine {
  vaccineId: VaccineId;
  illnessId: IllnessId;
  name: string;
}

export interface Vaccination {
  vaccinationId: VaccinationId;
  vaccineId: VaccineId;
  vaccinationDate: Date;
  waitPeriod: VaccinationWaitPeriod;
  nextVaccinationAt: Date;
  notice: string | null;
}

export interface InvoiceConfiguration {
  invoiceAddress: InvoiceAddressForPerson | null;
  isInvoiceEmailDispatchEnabled: boolean;
}

export interface Invoice {
  invoiceId: InvoiceId;
  invoiceNumber: string;
  invoiceDate: Moment;
  grossAmount: number;
  pdfUrl: string;
}

export interface FeedingPlan {
  feedingSettings: FeedingSetting[];
  feedingHistory: FeedingSettingsHistoryEntry[];
  feedTypes: FeedType[];
}

export interface FeedingSettingsHistoryEntry {
  feedingSettingsHistoryEntryId: FeedingSettingsHistoryEntryId;
  feedingConfiguration: FeedingConfiguration;
  type: FeedingSettingsHistoryEntryType;
  createdAt: Moment;
}

export interface FeedType {
  feedTypeId: FeedTypeId;
  name: string;
  isOfferedInTheMorning: boolean;
  isOfferedAtNoon: boolean;
  isOfferedInTheEvening: boolean;
  isUserAbleToDefineAmount: boolean;
  feedUnit: FeedUnit;
}

export interface FeedingSetting {
  feedingSettingId: FeedingSettingId;
  feedTypeId: FeedTypeId;
  isSelectedInTheMorning: boolean;
  isSelectedAtNoon: boolean;
  isSelectedInTheEvening: boolean;
  amountMorning: number | null;
  amountNoon: number | null;
  amountEvening: number | null;
}

export interface FeedingSettingPayload {
  feedTypeId: FeedTypeId;
  isSelectedInTheMorning: boolean;
  isSelectedAtNoon: boolean;
  isSelectedInTheEvening: boolean;
  amountMorning: number | null;
  amountNoon: number | null;
  amountEvening: number | null;
}

export interface PaddockAssignment {
  paddockName: string;
  timeFrame: TimeFrame;
}

export enum TrainingType {
  HORSE_TRAINING = 'HORSE_TRAINING',
  FACILITY_RESERVATION = 'FACILITY_RESERVATION',
}

export interface TrainingDiaryEntry {
  type: TrainingType;
  id: HorseTrainingSessionId | FacilityReservationId;
  facilityReservationId: FacilityReservationId | null;
  trainedFrom: Moment;
  trainedTo: Moment;
  strainOnHorse: Strain | null;
  rider: string;
  facility: string;
  activity: string;
  externalLink: Link | null;
  notes: string | null;
}

export interface PenExemptions {
  activePenExemption: PenExemption;
  penExemptions: PenExemption[];
  creationAvailableUntil: Time;
}

export interface PenExemption {
  penExemptionId: PenExemptionId;
  startingAt: Date;
  endingAt: Date;
  moveHorseTo: MoveHorseTo;
  comment: string | null;
}

export interface HorseWeight {
  latestWeightEntry: HorseWeightEntry | null;
  history: HorseWeightEntry[];
}

export interface HorseWeightEntry {
  horseWeightEntryId: HorseWeightEntryId;
  weighedAt: Date;
  weight: number;
  createdAt: Moment;
}

export interface HorseDrugs {
  latestDrugEntry: HorseDrugEntry | null;
  history: HorseDrugEntry[];
}

export interface HorseDrugEntry {
  horseDrugEntryId: HorseDrugEntryId;
  startingAt: Date;
  endingAt: Date;
  drugName: string;
  comment: string | null;
  createdAt: Moment;
}

export interface HorseFarrierAppointments {
  latestFarrierAppointment: HorseFarrierAppointment | null;
  history: HorseFarrierAppointment[];
}

export interface HorseFarrierAppointment {
  horseFarrierAppointmentId: HorseFarrierAppointmentId;
  date: Date;
  comment: string | null;
  createdAt: Moment;
}

export interface HorseVeterinarianAppointments {
  latestVeterinarianAppointment: HorseVeterinarianAppointment | null;
  history: HorseVeterinarianAppointment[];
}

export interface HorseVeterinarianAppointment {
  horseVeterinarianAppointmentId: HorseVeterinarianAppointmentId;
  date: Date;
  reason: string;
  comment: string | null;
  createdAt: Moment;
  image: BlurHashImageReadModel | null;
  attachmentUrl: string | null;
}

export interface HorseWormingTreatments {
  latestWormingTreatment: HorseWormingTreatment | null;
  history: HorseWormingTreatment[];
}

export interface HorseWormingTreatment {
  horseWormingTreatmentId: HorseWormingTreatmentId;
  date: Date;
  drugName: string;
  comment: string | null;
  createdAt: Moment;
}

export interface FarmService {
  farmServiceId: FarmServiceId;
  name: string;
  description: string | null;
  grossSellingPrice: number;
  taxRate: number | null;
  bookingType: FarmServiceBookingType;
  subscriptionConfiguration: FarmServiceSubscriptionConfiguration | null;
  oneOffConfiguration: FarmServiceOneOffConfiguration | null;
  enableWithFarmServiceBookings: FarmServiceIdList;
  disableWithFarmServiceBookings: FarmServiceIdList;
  paymentMethod: PaymentMethod;
  isEnabled: boolean;
  isArchived: boolean;
  bookings: FarmServiceBooking[];
}

export interface FarmServiceBooking {
  farmServiceBookingId: FarmServiceBookingId;
  startingAt: Date;
  endingAt: Date | null;
  additionalCosts: number;
  comment: string | null;
  lastPossibleCancellation: Date;
}

export interface GerblhofFeeding {
  activeFeeding: GerblhofFeedingUpdate | null;
  activeConcentratedFeedingUpdate: GerblhofConcentratedFeedingUpdate | null;
  feedingUpdates: GerblhofFeedingUpdate[];
  concentratedFeedingUpdates: GerblhofConcentratedFeedingUpdate[];
  feedingPricing: GerblhofFeedingPricing;
  concentratedFeedTypes: GerblhofConcentratedFeedType[];
}

export interface GerblhofFeedingPricing {
  defaultAmountHalfHayMorning: number;
  defaultAmountHalfHayEvening: number;
  halfHayUnitPrice: number;
  includedHalfHayAmount: number;
  maxHalfHayAmountPerFeeding: number;

  defaultAmountHayMorning: number;
  defaultAmountHayEvening: number;
  hayUnitPrice: number;
  includedHayAmount: number;
  maxHayAmountPerFeeding: number;

  feedingSwitchMorningPrice: number;
  feedingSwitchEveningPrice: number;

  specialFeedingEnabled: boolean;
  specialFeedingPrice: number | null;

  updatedAt: Moment | null;
}

export interface GerblhofConcentratedFeedType {
  concentratedFeedId: ConcentratedFeedId;
  name: string;
  createdAt: Moment;
}

export interface GerblhofFeedingUpdate {
  additionalCosts: number;
  halfHayAmountEvening: number;
  halfHayAmountMorning: number;
  hayAmountEvening: number;
  hayAmountMorning: number;
  specialFeedingEnabled: boolean;
  halfHayAmountNoon: number | null;
  hayAmountNoon: number | null;
  createdAt: Moment;
  startingAt: Date;
}

export interface GerblhofConcentratedFeeding {
  type: ConcentratedFeedId;
  amountMorning: number;
  amountNoon: number;
  amountEvening: number;
}

export interface GerblhofConcentratedFeedingUpdate {
  concentratedFeedings: GerblhofConcentratedFeeding[];
  ownFeedComment: string | null;
  startingAt: Date;
  createdAt: Moment;
}

export interface GerblhofFeedingOrConcentratedFeedingUpdate {
  type: string;
  details: GerblhofFeedingUpdate | GerblhofConcentratedFeedingUpdate;
}

// -- Queries

export interface GetGerblhofFeedingForHorseQuery extends Query {
  horseId: HorseId;
}

export interface GetInvoicePDFQuery extends Query {
  invoiceId: InvoiceId;
}

export interface GetSettlementForMonthQuery extends Query {
  month: Month;
}

export interface GetVaccinationCertificateForHorseQuery extends Query {
  horseId: HorseId;
}

export interface GetFeedingPlanQuery extends Query {
  horseId: HorseId;
}

export interface GetTrainingDiaryQuery extends Query {
  horseId: HorseId;
  numberOfWeeks: number;
}

export interface GetPenExemptionsForHorseQuery extends Query {
  horseId: HorseId;
}

export interface GetWeightForHorseQuery extends Query {
  horseId: HorseId;
}

export interface GetDrugsForHorseQuery extends Query {
  horseId: HorseId;
}

export interface GetFarrierAppointmentsForHorseQuery extends Query {
  horseId: HorseId;
}

export interface GetVeterinarianAppointmentsForHorseQuery extends Query {
  horseId: HorseId;
}

export interface GetWormingTreatmentsForHorseQuery extends Query {
  horseId: HorseId;
}

export interface GetFarmServicesForHorseQuery extends Query {
  horseId: HorseId;
}

// -- Commands

export interface DefineInvoiceAddressCommand extends Command {
  personId: PersonId;
  invoiceAddress: InvoiceAddressForPerson;
}

export interface UpdateInvoiceAddressCommand extends Command {
  personId: PersonId;
  invoiceAddress: InvoiceAddressForPerson;
}

export interface RemoveInvoiceAddressCommand extends Command {
  personId: PersonId;
}

export interface EnableInvoiceEmailDispatchCommand extends Command {}

export interface DisableInvoiceEmailDispatchCommand extends Command {}

export interface CreateFarmServiceBookingCommand extends Command {
  horseId: HorseId;
  farmServiceId: FarmServiceId;
  farmServiceBookingId: FarmServiceBookingId;
  startingAt: Date;
  comment: string | null;
}

export interface CancelFarmServiceBookingCommand extends Command {
  horseId: HorseId;
  farmServiceId: FarmServiceId;
  farmServiceBookingId: FarmServiceBookingId;
  startingAt: Date;
}

export interface CreatePenExemptionCommand extends Command {
  horseId: HorseId;
  startingAt: Date;
  endingAt: Date;
  moveHorseTo: MoveHorseTo;
  comment: string | null;
}

export interface DeletePenExemptionCommand extends Command {
  penExemptionId: PenExemptionId;
}

export interface UpdateFeedingCommand extends Command {
  feedingUpdateId: FeedingUpdateId;
  horseId: HorseId;
  specialFeedingEnabled: boolean;
  startingAt: Date;
  feedingTypeMorning: string;
  amountMorning: number;
  feedingTypeNoon: string | null;
  amountNoon: number | null;
  feedingTypeEvening: string;
  amountEvening: number;
}

export interface UpdateConcentratedFeedingCommand extends Command {
  horseId: HorseId;
  startingAt: Date;
  concentratedFeedings: GerblhofConcentratedFeeding[];
  ownFeedComment: string | null;
}

export interface RemoveFeedingUpdateCommand extends Command {
  horseId: HorseId;
  startingAt: Date;
}

export interface RemoveConcentratedFeedingUpdateCommand extends Command {
  horseId: HorseId;
  startingAt: Date;
}

export interface CreateVaccinationCommand extends Command {
  horseId: HorseId;
  illnessId: IllnessId;
  vaccineId: VaccineId;
  vaccinationDate: Date;
  nextVaccinationAfter: VaccinationWaitPeriod;
  notice: string | null;
}

export interface UpdateVaccinationNoticeCommand extends Command {
  vaccinationId: VaccinationId;
  notice: string | null;
}

export interface DeleteVaccinationCommand extends Command {
  vaccinationId: VaccinationId;
}

export interface CreateFeedingSettingsCommand extends Command {
  horseId: HorseId;
  feedingSettings: FeedingSettingPayload[];
}

export interface UpdateFeedingSettingsCommand extends Command {
  horseId: HorseId;
  feedingSettings: FeedingSettingPayload[];
}

export interface CreateHorseTrainingSessionCommand extends Command {
  horseId: HorseId;
  trainingTimeFrame: MomentTimeFrame;
  facility: string;
  activity: string;
  rider: string;
  strainOnHorse: Strain;
  externalLink: Link | null;
  notes: string | null;
}

export interface CreateHorseTrainingSessionForFacilityReservationCommand extends Command {
  facilityReservationId: FacilityReservationId;
  facility: string;
  activity: string;
  rider: string;
  strainOnHorse: Strain;
  externalLink: Link | null;
  notes: string | null;
}

export interface UpdateHorseTrainingSessionCommand extends Command {
  horseTrainingSessionId: HorseTrainingSessionId;
  trainingTimeFrame: MomentTimeFrame;
  facility: string;
  activity: string;
  rider: string;
  strainOnHorse: Strain;
  externalLink: Link | null;
  notes: string | null;
}

export interface DeleteHorseTrainingSessionCommand extends Command {
  horseTrainingSessionId: HorseTrainingSessionId;
}

export interface CreateHorseWeightEntryCommand extends Command {
  horseId: HorseId;
  weighedAt: Date;
  weight: number;
}

export interface DeleteHorseWeightEntryCommand extends Command {
  horseWeightEntryId: HorseWeightEntryId;
}

export interface CreateHorseDrugEntryCommand extends Command {
  horseId: HorseId;
  startingAt: Date;
  endingAt: Date;
  drugName: string;
  comment: string | null;
}

export interface DeleteHorseDrugEntryCommand extends Command {
  horseDrugEntryId: HorseDrugEntryId;
}

export interface CreateHorseFarrierAppointmentCommand extends Command {
  horseId: HorseId;
  date: Date;
  comment: string | null;
}

export interface DeleteHorseFarrierAppointmentCommand extends Command {
  horseFarrierAppointmentId: HorseFarrierAppointmentId;
}

export interface CreateHorseWormingTreatmentCommand extends Command {
  horseId: HorseId;
  date: Date;
  drugName: string;
  comment: string | null;
}

export interface DeleteHorseWormingTreatmentCommand extends Command {
  horseWormingTreatmentId: HorseWormingTreatmentId;
}

export interface CreateHorseVeterinarianAppointmentCommand extends CommandWithFiles {
  body: {
    horseId: HorseId;
    date: Date;
    reason: string;
    comment: string | null;
  };
  files: {
    image: File | null;
    attachment: File | null;
  }
}

export interface DeleteHorseVeterinarianAppointmentCommand extends Command {
  horseVeterinarianAppointmentId: HorseVeterinarianAppointmentId;
}

export interface UpdatePhoneNumberCommand extends Command {
  phoneNumber: PhoneNumber;
  isPhoneNumberVisibleOnBoxSign: boolean;
}
