import { render, staticRenderFns } from "./custom-booking-multi-checkbox-form-control.vue?vue&type=template&id=52e095b3&scoped=true&"
import script from "./custom-booking-multi-checkbox-form-control.vue?vue&type=script&setup=true&lang=ts&"
export * from "./custom-booking-multi-checkbox-form-control.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./custom-booking-multi-checkbox-form-control.vue?vue&type=style&index=0&id=52e095b3&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52e095b3",
  null
  
)

export default component.exports