import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { apiUrl, performApiRequest } from '@/infrastructure';
import { UpdateEmergencyContactOfUser } from './types';

// -- Commands

export function updateEmergencyContactOfUser(query: UpdateEmergencyContactOfUser.UpdateEmergencyContactOfUserCommand): Promise<void> {
  const url = `${apiUrl}/api/profile/update-emergency-contact-of-user-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}
