import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { apiUrl, performApiRequest } from '@/infrastructure';
import { GetMedicalRecordsForHorseAsManager } from './types';

// -- Queries

export function getMedicalRecordsForHorseAsManager(
  query: GetMedicalRecordsForHorseAsManager.GetMedicalRecordsForHorseAsManagerQuery
): Promise<GetMedicalRecordsForHorseAsManager.MedicalRecords> {
  const url = `${apiUrl}/api/medical-records/get-medical-records-for-horse-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}
