import { attachFarmAndUserProperties } from '@/helpers/default-parameter-helper';
import { apiUrl, performApiRequest } from '@/infrastructure';
import { GetActivePenExemptionForHorseAsManager } from './types';

// -- Queries

export function getActivePenExemptionForHorseAsManager(
  query: GetActivePenExemptionForHorseAsManager.GetActivePenExemptionForHorseAsManagerQuery
): Promise<GetActivePenExemptionForHorseAsManager.Response> {
  const url = `${apiUrl}/api/pen/get-active-pen-exemption-for-horse-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}
