import { Moment } from '@/types/moment';
import { Time, TimeFrame } from '@/types/time';
import { moment } from '@/helpers/moment';
import { Date } from './date';

export type Id = string;

export type AdminNotificationId = Id;
export type BookingId = Id;
export type StableId = Id;
export type BoxSignGenerationProcessForAllHorsesId = Id;
export type BoxId = Id;
export type BoxPlanAssignmentId = Id;
export type BoxRowId = Id;
export type ConcentratedFeedingUpdateId = Id;
export type ConcentratedFeedId = Id;
export type CreditNoteId = Id;
export type CustomBookingId = Id;
export type CustomBookingServiceId = Id;
export type CustomFieldId = Id;
export type FacilityId = Id;
export type FacilityBlockerId = Id;
export type FacilityReservationId = Id;
export type FacilityReservationActivityId = Id;
export type FarmChangeId = Id;
export type FarmDocumentId = Id;
export type FarmId = Id;
export type FarmServiceId = Id;
export type FarmTaskId = Id;
export type FarmServiceBookingId = Id;
export type FarmServiceUsageId = Id;
export type FeedTypeId = Id;
export type FeedingUpdateId = Id;
export type FeedingSettingId = Id;
export type FeedingSettingsHistoryEntryId = Id;
export type FeedProtocolEntryId = Id;
export type FeedProtocolStationId = Id;
export type FreeJumpingFreeRunningEventId = Id;
export type FreeJumpingFreeRunningEventRegistrationId = Id;
export type EventId = Id;
export type EventSourcingEventId = Id;
export type EventRegistrationId = Id;
export type EventRegistrationRequestId = Id;
export type EventRegistrationCancellationRequestId = Id;
export type HorseId = Id;
export type HorseDocumentId = Id;
export type HorseDrugEntryId = Id;
export type HorseFarrierAppointmentId = Id;
export type HorseTrainingSessionId = Id;
export type HorseWeightEntryId = Id;
export type HorseVeterinarianAppointmentId = Id;
export type HorseWormingTreatmentId = Id;
export type HorseShareId = Id;
export type HorseShareInviteId = Id;
export type HorseWalkerBlockerId = Id;
export type HorseWalkerEntryId = Id;
export type HorseWalkerPlanTimeRangeId = Id;
export type HorseWalkerPlanTimeRangeSlotId = Id;
export type IllnessId = Id;
export type ImpersonationRequestTokenId = Id;
export type InvoiceId = Id;
export type LaborServiceTimeEntryId = Id;
export type NotificationId = Id;
export type OrganizationId = Id;
export type OptionId = Id;
export type PaddockPlanAssignmentId = Id;
export type PaddockPlanPaddockId = Id;
export type PaddockPlanTimeRangeId = Id;
export type PenStatusEntryId = Id;
export type PersonDocumentId = Id;
export type PersonId = Id;
export type PasswordHash = Id;
export type PasswordResetRequestTokenId = Id;
export type PenExemptionId = Id;
export type PushNotificationConfigurationId = Id;
export type NewsEntryId = Id;
export type RiderTaskId = Id;
export type RidingLessonId = Id;
export type RidingLessonSeriesId = Id;
export type RidingLessonRegistrationId = Id;
export type RidingLessonTypeId = Id;
export type SettlementCacheEntryId = Id;
export type SettlementCacheLogId = Id;
export type UserGroupId = Id;
export type UserId = Id;
export type UserInviteId = Id;
export type UserRegistrationRequestId = Id;
export type VaccineId = Id;
export type VaccinationId = Id;
export type SurveyId = Id;
export type SurveyAnswerId = Id;

export type CustomBookingServiceName = string;
export type CustomFieldLabel = string;
export type EmailAddress = string;
export type EquineNumber = string;
export type FarmServiceDescription = string;
export type FarmServiceName = string;
export type InvoiceNumber = string;
export type Link = string;
export type OrganizationName = string;
export type PhoneNumber = string;
export type RegistrationCode = string;
export type Salutation = string;
export type SearchTerm = string;

export type LanguageWithoutGerman = Exclude<Language, Language.de>;

export type CustomBookingIdList = CustomBookingId[];
export type CustomBookingServiceIdList = CustomBookingServiceId[];
export type CreditNoteIdList = CreditNoteId[];
export type FarmServiceIdList = FarmServiceId[];
export type HorseIdList = HorseId[];
export type OptionIdList = OptionId[];
export type PersonIdList = PersonId[];
export type UserGroupIdList = UserGroupId[];
export type UserIdList = UserId[];
export type RidingLessonIdList = RidingLessonId[];

export type RidingLessonDuration = number;
export type Limit = number;

export enum RidingLessonRegistrationType {
  USER = 'USER',
  IN_NAME_OF_ANOTHER_PERSON = 'IN_NAME_OF_ANOTHER_PERSON',
}

export enum EventRegistrationType {
  USER = 'USER',
  EXTERNAL_GUEST = 'EXTERNAL_GUEST',
}

export enum Strain {
  MINIMAL_STRAIN = 'MINIMAL_STRAIN',
  MODERATE_STRAIN = 'MODERATE_STRAIN',
  EXTENSIVE_STRAIN = 'EXTENSIVE_STRAIN',
}

export interface MomentTimeFrame {
  momentFrom: Moment;
  momentTo: Moment;
}

export interface DateTimeFrame {
  from: Date;
  to: Date;
}

export enum Persona {
  HORSE_OWNER = 'HORSE_OWNER',
  RIDING_PARTICIPANT = 'RIDING_PARTICIPANT',
  WITHOUT_HORSE_ASSIGNMENT = 'WITHOUT_HORSE_ASSIGNMENT',
}

export enum CalendarViewPreference {
  AUTOMATIC = 'AUTOMATIC',
  DAY_FIRST = 'DAY_FIRST',
  WEEK_FIRST = 'WEEK_FIRST',
}

export enum FeatureVisibleForRiders {
  NEWS = 'NEWS',
  EVENTS = 'EVENTS',
  GERBLHOF_FEEDING = 'GERBLHOF_FEEDING',
  FARM_SERVICES = 'FARM_SERVICES',
  PEN_EXEMPTIONS = 'PEN_EXEMPTIONS',
  FEEDING = 'FEEDING',
  PEN_STATUS = 'PEN_STATUS',
  FARM_DOCUMENTS = 'FARM_DOCUMENTS',
  INVOICING = 'INVOICING',
}
export type FeaturesVisibleForRiders = FeatureVisibleForRiders[];

export enum Feature {
  SURVEYS = 'SURVEYS',
  BOX_PLAN = 'BOX_PLAN',
  PADDOCK_PLAN = 'PADDOCK_PLAN',
  LEDGER = 'LEDGER',
  EVENTS = 'EVENTS',
  RIDER_TASKS = 'RIDER_TASKS',
  FARM_TASKS = 'FARM_TASKS',
  LABOR_SERVICE = 'LABOR_SERVICE',
  INVOICING = 'INVOICING',
  FACILITY_RESERVATIONS = 'FACILITY_RESERVATIONS',
  RIDING_LESSONS = 'RIDING_LESSONS',
  FEED_PROTOCOL = 'FEED_PROTOCOL',
  FEEDING = 'FEEDING',
  PEN = 'PEN',
  CUSTOMER_MASTER_DATA = 'CUSTOMER_MASTER_DATA',
  FARM_SERVICES = 'FARM_SERVICES',
}

export enum Currency {
  EUR = 'EUR',
  CHF = 'CHF',
}

export enum Locale {
  'de-DE' = 'de-DE',
  'de-CH' = 'de-CH',
  'de-IT' = 'de-IT',
}

export enum UserRole {
  ROLE_USER = 'ROLE_USER',
  ROLE_FARM_MANAGER = 'ROLE_FARM_MANAGER',
  ROLE_ADMIN = 'ROLE_ADMIN',
}

export enum Country {
  'de' = 'de',
  'at' = 'at',
  'ch' = 'ch',
  'fr' = 'fr',
  'it' = 'it',
  'es' = 'es',
  'nl' = 'nl',
  'be' = 'be',
  'lu' = 'lu',
  'pt' = 'pt',
}

export enum VaccinationWaitPeriod {
  FOUR_WEEKS = 'FOUR_WEEKS',
  EIGHT_WEEKS = 'EIGHT_WEEKS',
  SIX_MONTHS = 'SIX_MONTHS',
  EIGHT_MONTHS = 'EIGHT_MONTHS',
  NINE_MONTHS = 'NINE_MONTHS',
  ONE_YEAR = 'ONE_YEAR',
  TWO_YEARS = 'TWO_YEARS',
  THREE_YEARS = 'THREE_YEARS',
}

export enum VaccinationStatus {
  VACCINATED = 'VACCINATED',
  EXPIRES_SOON = 'EXPIRES_SOON',
  EXPIRED = 'EXPIRED',
  NOT_VACCINATED = 'NOT_VACCINATED',
}

export enum FeedUnit {
  GRAM = 'GRAM',
  KILOGRAM = 'KILOGRAM',
  SHOVEL = 'SHOVEL',
  BUCKET = 'BUCKET',
  PITCHFORK = 'PITCHFORK',
  LITER = 'LITER',
  WHEELBARROW = 'WHEELBARROW',
  MILLILITER = 'MILLILITER',
  PIECE = 'PIECE',
  MEASURING_SPOON = 'MEASURING_SPOON',
  MEASURING_CUP = 'MEASURING_CUP',
  CAN = 'CAN',
  BOWL = 'BOWL',
}

export enum FeedProtocolMonitoringPausedUntil {
  NEXT_FEEDING = 'NEXT_FEEDING',
  UNPAUSED = 'UNPAUSED',
}

export enum FarmManagerPermission {
  SURVEYS_READ = 'SURVEYS_READ',
  SURVEYS_WRITE = 'SURVEYS_WRITE',
  BOX_PLAN_READ = 'BOX_PLAN_READ',
  BOX_PLAN_WRITE = 'BOX_PLAN_WRITE',
  PADDOCK_PLAN_READ = 'PADDOCK_PLAN_READ',
  PADDOCK_PLAN_WRITE = 'PADDOCK_PLAN_WRITE',
  PEN_READ = 'PEN_READ',
  PEN_WRITE = 'PEN_WRITE',
  LEDGER_READ = 'LEDGER_READ',
  LEDGER_WRITE = 'LEDGER_WRITE',
  INVOICING_READ = 'INVOICING_READ',
  INVOICING_WRITE = 'INVOICING_WRITE',
  EVENTS_READ = 'EVENTS_READ',
  EVENTS_WRITE = 'EVENTS_WRITE',
  NEWS_READ = 'NEWS_READ',
  NEWS_WRITE = 'NEWS_WRITE',
  RIDER_TASKS_READ = 'RIDER_TASKS_READ',
  RIDER_TASKS_WRITE = 'RIDER_TASKS_WRITE',
  FARM_TASKS_READ = 'FARM_TASKS_READ',
  FARM_TASKS_WRITE = 'FARM_TASKS_WRITE',
  LABOR_SERVICE_READ = 'LABOR_SERVICE_READ',
  LABOR_SERVICE_WRITE = 'LABOR_SERVICE_WRITE',
  FACILITIES_READ = 'FACILITIES_READ',
  FACILITIES_WRITE = 'FACILITIES_WRITE',
  FACILITY_RESERVATIONS_READ = 'FACILITY_RESERVATIONS_READ',
  FACILITY_RESERVATIONS_WRITE = 'FACILITY_RESERVATIONS_WRITE',
  RIDING_LESSONS_READ = 'RIDING_LESSONS_READ',
  RIDING_LESSONS_CREATE = 'RIDING_LESSONS_CREATE',
  RIDING_LESSONS_UPDATE = 'RIDING_LESSONS_UPDATE',
  RIDING_LESSONS_DELETE = 'RIDING_LESSONS_DELETE',
  RIDING_LESSONS_REGISTRATIONS_WRITE = 'RIDING_LESSONS_REGISTRATIONS_WRITE',
  RIDING_LESSONS_ASSIGN_HORSES = 'RIDING_LESSONS_ASSIGN_HORSES',
  RIDING_LESSONS_UPDATE_CONFIGURATION = 'RIDING_LESSONS_UPDATE_CONFIGURATION',
  FEED_PROTOCOL_READ = 'FEED_PROTOCOL_READ',
  FEED_PROTOCOL_WRITE = 'FEED_PROTOCOL_WRITE',
  MEDICAL_RECORDS_READ = 'MEDICAL_RECORDS_READ',
  MEDICAL_RECORDS_WRITE = 'MEDICAL_RECORDS_WRITE',
  FEEDING_READ = 'FEEDING_READ',
  FEEDING_WRITE = 'FEEDING_WRITE',
  ANALYTICS_VIEW = 'ANALYTICS_VIEW',
  FARM_DOCUMENTS_READ = 'FARM_DOCUMENTS_READ',
  FARM_DOCUMENTS_WRITE = 'FARM_DOCUMENTS_WRITE',
  USERS_READ = 'USERS_READ',
  USERS_WRITE = 'USERS_WRITE',
  HORSES_READ = 'HORSES_READ',
  HORSES_WRITE = 'HORSES_WRITE',
  CUSTOMER_MASTER_DATA_READ = 'CUSTOMER_MASTER_DATA_READ',
  CUSTOMER_MASTER_DATA_WRITE = 'CUSTOMER_MASTER_DATA_WRITE',
  FARM_SERVICES_READ = 'FARM_SERVICES_READ',
  FARM_SERVICES_WRITE = 'FARM_SERVICES_WRITE',
}

export interface FarmManagerPermissions {
  areAllPermissionsEnabled: boolean;
  permissions: FarmManagerPermission[];
}

export enum Color {
  'green-5' = 'green-5',
  'yellow-5' = 'yellow-5',
  'red-5' = 'red-5',
  'green-8' = 'green-8',
  'yellow-8' = 'yellow-8',
  'red-8' = 'red-8',
  'grey-8' = 'grey-8',
  'green-3' = 'green-3',
  'yellow-3' = 'yellow-3',
  'red-3' = 'red-3',
}

export enum Weekday {
  'MONDAY' = 'MONDAY',
  'TUESDAY' = 'TUESDAY',
  'WEDNESDAY' = 'WEDNESDAY',
  'THURSDAY' = 'THURSDAY',
  'FRIDAY' = 'FRIDAY',
  'SATURDAY' = 'SATURDAY',
  'SUNDAY' = 'SUNDAY',
}

export function weekdayFromMoment(moment: Moment): Weekday {
  switch (moment.isoWeekday()) {
    case 1:
      return Weekday.MONDAY;
    case 2:
      return Weekday.TUESDAY;
    case 3:
      return Weekday.WEDNESDAY;
    case 4:
      return Weekday.THURSDAY;
    case 5:
      return Weekday.FRIDAY;
    case 6:
      return Weekday.SATURDAY;
    case 7:
      return Weekday.SUNDAY;
    default:
      throw new Error('Invalid weekday');
  }
}

export function weekdayFromDate(date: Date): Weekday {
  switch (moment(date.date).isoWeekday()) {
    case 1:
      return Weekday.MONDAY;
    case 2:
      return Weekday.TUESDAY;
    case 3:
      return Weekday.WEDNESDAY;
    case 4:
      return Weekday.THURSDAY;
    case 5:
      return Weekday.FRIDAY;
    case 6:
      return Weekday.SATURDAY;
    case 7:
      return Weekday.SUNDAY;
    default:
      throw new Error('Invalid weekday');
  }
}

export function isoDayOfWeek(weekday: Weekday): number {
  switch (weekday) {
    case Weekday.MONDAY:
      return 1;
    case Weekday.TUESDAY:
      return 2;
    case Weekday.WEDNESDAY:
      return 3;
    case Weekday.THURSDAY:
      return 4;
    case Weekday.FRIDAY:
      return 5;
    case Weekday.SATURDAY:
      return 6;
    case Weekday.SUNDAY:
      return 7;
    default:
      throw new Error('Invalid weekday');
  }
}

export enum SortDirection {
  'ASC' = 'ASC',
  'DESC' = 'DESC',
}

export function sortDirection(sortDesc: boolean): SortDirection {
  return sortDesc
    ? SortDirection.DESC
    : SortDirection.ASC;
}

export enum NotificationType {
  NEWS_ENTRY_PUBLISHED = 'NEWS_ENTRY_PUBLISHED',
  EVENT_PUBLISHED = 'EVENT_PUBLISHED',
  EVENT_CANCELED = 'EVENT_CANCELED',
  RIDER_TASK_CREATED = 'RIDER_TASK_CREATED',
  RIDER_TASK_ASSIGNED = 'RIDER_TASK_ASSIGNED',
  RIDER_TASK_ASSIGNED_WITH_EXECUTION_DATE_UPDATE = 'RIDER_TASK_ASSIGNED_WITH_EXECUTION_DATE_UPDATE',
  RIDER_TASK_ASSIGNED_TO_YOU = 'RIDER_TASK_ASSIGNED_TO_YOU',
  RIDER_TASK_REJECTED = 'RIDER_TASK_REJECTED',
  RIDER_TASK_REJECTED_WITH_REASON = 'RIDER_TASK_REJECTED_WITH_REASON',
  RIDER_TASK_COMPLETED = 'RIDER_TASK_COMPLETED',
  RIDER_TASK_COMPLETED_WITH_COMMENT = 'RIDER_TASK_COMPLETED_WITH_COMMENT',
  RIDER_TASK_WITHDRAWN = 'RIDER_TASK_WITHDRAWN',
  RIDER_TASK_CREATED_ASSIGNED = 'RIDER_TASK_CREATED_ASSIGNED',
  FARM_TASK_CREATED = 'FARM_TASK_CREATED',
  FARM_TASK_ASSIGNED_TO_YOU = 'FARM_TASK_ASSIGNED_TO_YOU',
  FARM_TASK_YOU_HAVE_BEEN_REMOVED_FROM_TASK = 'FARM_TASK_YOU_HAVE_BEEN_REMOVED_FROM_TASK',
  FARM_TASK_EXECUTION_DATE_UPDATED = 'FARM_TASK_EXECUTION_DATE_UPDATED',
  FARM_TASK_DELETED = 'FARM_TASK_DELETED',
  FEED_PROTOCOL_MISSING_SIGNAL = 'FEED_PROTOCOL_MISSING_SIGNAL',
  IMPERSONATION_REQUESTED = 'IMPERSONATION_REQUESTED',
  IMPERSONATION_CONFIRMED = 'IMPERSONATION_CONFIRMED',
  VACCINATION_EXPIRES_SOON = 'VACCINATION_EXPIRES_SOON',
  FACILITY_RESERVATION_CANCELED_DUE_TO_FACILITY_BLOCKER = 'FACILITY_RESERVATION_CANCELED_DUE_TO_FACILITY_BLOCKER',
  FACILITY_RESERVATION_CANCELED_DUE_TO_REDUCED_OPENING_HOURS = 'FACILITY_RESERVATION_CANCELED_DUE_TO_REDUCED_OPENING_HOURS',
  FACILITY_RESERVATION_CANCELED_DUE_TO_DISABLED_ACTIVITY = 'FACILITY_RESERVATION_CANCELED_DUE_TO_DISABLED_ACTIVITY',
  FACILITY_RESERVATION_CANCELED_DUE_TO_RIDING_LESSON = 'FACILITY_RESERVATION_CANCELED_DUE_TO_RIDING_LESSON',
  RIDING_LESSON_RESCHEDULED = 'RIDING_LESSON_RESCHEDULED',
  RIDING_LESSON_TYPE_UPDATED = 'RIDING_LESSON_TYPE_UPDATED',
  INVITED_AS_RIDE_SHARE = 'INVITED_AS_RIDE_SHARE',
  LABOR_SERVICE_ENTRY_CREATED = 'LABOR_SERVICE_ENTRY_CREATED',
  LABOR_SERVICE_ENTRY_APPROVED = 'LABOR_SERVICE_ENTRY_APPROVED',
  LABOR_SERVICE_ENTRY_REJECTED = 'LABOR_SERVICE_ENTRY_REJECTED',
  USER_DELETED_OWN_ACCOUNT = 'USER_DELETED_OWN_ACCOUNT',
  SURVEY_CREATED = 'SURVEY_CREATED',
  SURVEY_ANSWER_DELETED = 'SURVEY_ANSWER_DELETED',
}
export interface NotificationConfiguration {
  type: NotificationType;
  payload: NotificationPayload;
}
export interface NotificationPayload {
  context: string;
  notificationId: NotificationId;
}

export type RelevantFirstTwoYearDigits = '19' | '20';
export type MonthOption = '01' | '02' |'03' |'04' |'05' |'06' |'07' |'08' |'09' |'10' |'11' |'12';
export type Month = `${RelevantFirstTwoYearDigits}${number}${number}-${MonthOption}`;

export enum RiderTaskStatus {
  NEW = 'NEW',
  ASSIGNED = 'ASSIGNED',
  REJECTED = 'REJECTED',
  WITHDRAWN = 'WITHDRAWN',
  COMPLETED = 'COMPLETED',
}

export enum RiderTaskHistoryEntryType {
  CREATED = 'CREATED',
  CREATED_ASSIGNED = 'CREATED_ASSIGNED',
  ASSIGNED = 'ASSIGNED',
  UPDATED_EXECUTION_DATE = 'UPDATED_EXECUTION_DATE',
  REJECTED = 'REJECTED',
  WITHDRAWN = 'WITHDRAWN',
  COMPLETED = 'COMPLETED',
}

export interface NumberRange {
  from: number;
  to: number;
}

export enum ReservationDisplayNameType {
  HORSE = 'HORSE',
  RESERVING_PERSON = 'RESERVING_PERSON',
}

export enum MimeType {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  PDF = 'application/pdf',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

export interface FeedingConfiguration {
  feedingsMorning: Feeding[];
  feedingsNoon: Feeding[];
  feedingsEvening: Feeding[];
}

export interface Feeding {
  feedTypeId: FeedTypeId;
  feedTypeName: string;
  amount: number | null;
  feedUnit: FeedUnit | null;
}

export enum FeedingSettingsHistoryEntryType {
  USER_UPDATE = 'USER_UPDATE',
  FEED_TYPE_DISABLING = 'FEED_TYPE_DISABLING',
  FEED_TYPE_OFFER_REDUCED = 'FEED_TYPE_OFFER_REDUCED',
}

export enum BoxSignGenerationProcessForAllHorsesStatus {
  PLANNED = 'PLANNED',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
}

export enum Repetition {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
}

export interface FarmTaskRepetition {
  repetition: Repetition;
  weekdays: Weekday[] | null;
  lastRepetitionAt: Date | null;
}

export enum MoveHorseTo {
  PADDOCK = 'PADDOCK',
  BOX = 'BOX',
}

export interface Name {
  title: string | null;
  firstName: string;
  lastName: string;
}

export interface Address {
  street: string;
  postal: string;
  city: string;
  country: Country;
}

export interface InvoiceAddressForPerson {
  company: string | null;
  salutation: Salutation | null;
  name: Name;
  address: Address;
}

export interface InvoiceAddressForOrganization {
  company: string;
  salutationOfContactPerson: Salutation | null;
  nameOfContactPerson: Name | null;
  address: Address;
}

export enum Language {
  'de' = 'de',
  'en' = 'en',
  'fr' = 'fr',
}
export const languagesWithoutGerman: LanguageWithoutGerman[] = [
  Language.en,
  Language.fr,
];

export interface FarmAddress {
  company: string;
  address: Address;
}

export enum TimeConfigurationType {
  WITHOUT_TIME = 'WITHOUT_TIME',
  WITH_TIME = 'WITH_TIME',
  WITH_TIME_FRAME = 'WITH_TIME_FRAME',
}

export interface TimeConfiguration {
  type: TimeConfigurationType;
  time: Time | null;
  timeFrame: TimeFrame | null;
}

export enum CustomFieldType {
  TEXT = 'TEXT',
  LONGTEXT = 'LONGTEXT',
  YES_NO = 'YES_NO',
  SELECT = 'SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  MULTI_TEXT = 'MULTI_TEXT',
  MULTI_TEXT_AND_DATE = 'MULTI_TEXT_AND_DATE',
  MULTI_TEXT_AND_DATE_TIME_FRAME = 'MULTI_TEXT_AND_DATE_TIME_FRAME',
}

export const customFieldTypesWithSelect = [
  CustomFieldType.SELECT,
  CustomFieldType.MULTI_SELECT,
  CustomFieldType.YES_NO,
];

export function iconForCustomFieldType(customFieldType: CustomFieldType): string {
  switch (customFieldType) {
    case CustomFieldType.TEXT:
    case CustomFieldType.LONGTEXT:
      return 'text';
    case CustomFieldType.YES_NO:
      return 'check-square';
    case CustomFieldType.SELECT:
    case CustomFieldType.MULTI_SELECT:
      return 'list';
    case CustomFieldType.MULTI_TEXT:
    case CustomFieldType.MULTI_TEXT_AND_DATE:
    case CustomFieldType.MULTI_TEXT_AND_DATE_TIME_FRAME:
      return 'rectangle-history';
    default:
      throw new Error(`Invalid custom field type: ${customFieldType}`);
  }
}

export enum CustomerType {
  PERSON = 'PERSON',
  ORGANISATION = 'ORGANISATION',
}

export type Options = Option[];
export interface Option {
  optionId: OptionId;
  label: string;
}

export type CustomFieldValues = CustomFieldValue[];

export interface CustomFieldValueBase {
  customFieldId: CustomFieldId;
  customFieldType: CustomFieldType;
  value: string | boolean | OptionId | OptionId[] | MultiText | MultiTextAndDate | MultiTextAndDateTimeFrame;
}

export type CustomFieldValue =
  | CustomFieldValueForText
  | CustomFieldValueForSelect
  | CustomFieldValueForMultiSelect
  | CustomFieldValueForYesNo
  | CustomFieldValueForMultiText
  | CustomFieldValueForMultiTextAndDate
  | CustomFieldValueForMultiTextAndDateTimeFrame;

export interface CustomFieldValueForText extends CustomFieldValueBase {
  customFieldType: CustomFieldType.TEXT | CustomFieldType.LONGTEXT;
  value: string;
}

export function isCustomFieldValueForText(customFieldValue: CustomFieldValue): customFieldValue is CustomFieldValueForText {
  return customFieldValue.customFieldType === CustomFieldType.TEXT
    || customFieldValue.customFieldType === CustomFieldType.LONGTEXT;
}

export interface CustomFieldValueForSelect extends CustomFieldValueBase {
  customFieldType: CustomFieldType.SELECT;
  value: OptionId;
}

export function isCustomFieldValueForSelect(customFieldValue: CustomFieldValue): customFieldValue is CustomFieldValueForSelect {
  return customFieldValue.customFieldType === CustomFieldType.SELECT;
}

export interface CustomFieldValueForMultiSelect extends CustomFieldValueBase {
  customFieldType: CustomFieldType.MULTI_SELECT;
  value: OptionId[];
}

export function isCustomFieldValueForMultiSelect(customFieldValue: CustomFieldValue): customFieldValue is CustomFieldValueForMultiSelect {
  return customFieldValue.customFieldType === CustomFieldType.MULTI_SELECT;
}

export interface CustomFieldValueForYesNo extends CustomFieldValueBase {
  customFieldType: CustomFieldType.YES_NO;
  value: boolean;
}

export function isCustomFieldValueForYesNo(customFieldValue: CustomFieldValue): customFieldValue is CustomFieldValueForYesNo {
  return customFieldValue.customFieldType === CustomFieldType.YES_NO;
}

export interface CustomFieldValueForMultiText extends CustomFieldValueBase {
  customFieldType: CustomFieldType.MULTI_TEXT;
  value: string[];
}

export function isCustomFieldValueForMultiText(customFieldValue: CustomFieldValue): customFieldValue is CustomFieldValueForMultiText {
  return customFieldValue.customFieldType === CustomFieldType.MULTI_TEXT;
}

export interface CustomFieldValueForMultiTextAndDate extends CustomFieldValueBase {
  customFieldType: CustomFieldType.MULTI_TEXT_AND_DATE;
  value: MultiTextAndDate;
}

export function isCustomFieldValueForMultiTextAndDate(
  customFieldValue: CustomFieldValue
): customFieldValue is CustomFieldValueForMultiTextAndDate {
  return customFieldValue.customFieldType === CustomFieldType.MULTI_TEXT_AND_DATE;
}

export interface CustomFieldValueForMultiTextAndDateTimeFrame extends CustomFieldValueBase {
  customFieldType: CustomFieldType.MULTI_TEXT_AND_DATE_TIME_FRAME;
  value: MultiTextAndDateTimeFrame;
}

export function isCustomFieldValueForMultiTextAndDateTimeFrame(
  customFieldValue: CustomFieldValue
): customFieldValue is CustomFieldValueForMultiTextAndDateTimeFrame {
  return customFieldValue.customFieldType === CustomFieldType.MULTI_TEXT_AND_DATE_TIME_FRAME;
}

export type MultiText = string[];

export type MultiTextAndDate = TextAndDate[];
export type TextAndDate = {
  text: string;
  date: Date;
}

export type MultiTextAndDateTimeFrame = TextAndDateTimeFrame[];
export type TextAndDateTimeFrame = {
  text: string;
  dateTimeFrame: DateTimeFrame;
}

export type PersonChangeHistory = PersonChangeHistoryEntry[];
export interface PersonChangeHistoryEntry {
  type: PersonChangeType;
  changedAt: Moment;
  idOfUser: UserId;
  nameOfUser: Name;
}
export enum PersonChangeType {
  PERSON_CREATED = 'PERSON_CREATED',
  UPDATED_SALUTATION = 'UPDATED_SALUTATION',
  UPDATED_NAME = 'UPDATED_NAME',
  UPDATED_EMAIL_ADDRESS = 'UPDATED_EMAIL_ADDRESS',
  UPDATED_ADDRESS = 'UPDATED_ADDRESS',
  UPDATED_INVOICE_ADDRESS = 'UPDATED_INVOICE_ADDRESS',
  UPDATED_ADDITIONAL_MASTER_DATA = 'UPDATED_ADDITIONAL_MASTER_DATA',
  REMOVED_CONNECTED_USER = 'REMOVED_CONNECTED_USER',
  CONNECTED_USER_DELETED_OWN_ACCOUNT = 'CONNECTED_USER_DELETED_OWN_ACCOUNT',
  ARCHIVED_PERSON = 'ARCHIVED_PERSON',
  CREATED_PERSON_DOCUMENT = 'CREATED_PERSON_DOCUMENT',
  DELETED_PERSON_DOCUMENT = 'DELETED_PERSON_DOCUMENT',
  ASSIGNED_OPTION_TO_PERSONS = 'ASSIGNED_OPTION_TO_PERSONS',
}

export type OrganizationChangeHistory = OrganizationChangeHistoryEntry[];
export interface OrganizationChangeHistoryEntry {
  type: OrganizationChangeType;
  changedAt: Moment;
  idOfUser: UserId;
  nameOfUser: Name;
}
export enum OrganizationChangeType {
  ORGANIZATION_CREATED = 'ORGANIZATION_CREATED',
  UPDATED_NAME = 'UPDATED_NAME',
  UPDATED_ADDRESS = 'UPDATED_ADDRESS',
  UPDATED_INVOICE_ADDRESS = 'UPDATED_INVOICE_ADDRESS',
  UPDATED_ADDITIONAL_MASTER_DATA = 'UPDATED_ADDITIONAL_MASTER_DATA',
  UPDATED_ASSIGNED_PERSONS = 'UPDATED_ASSIGNED_PERSONS',
  ARCHIVED_ORGANIZATION = 'ARCHIVED_ORGANIZATION',
}

export enum FarmServiceBookingType {
  SUBSCRIPTION = 'SUBSCRIPTION',
  ONE_OFF = 'ONE_OFF',
}

export interface Price {
  grossSellingPrice: number;
  netSellingPrice: number;
  taxRate: number;
  taxAmount: number;
}

export enum SubscriptionSchedule {
  ONE_MONTH = 'ONE_MONTH',
  THREE_MONTHS = 'THREE_MONTHS',
  SIX_MONTHS = 'SIX_MONTHS',
  ONE_YEAR = 'ONE_YEAR',
}

export enum PaymentMethod {
  BANK_TRANSFER = 'BANK_TRANSFER',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
}

export enum NameOrderType {
  FIRST_NAME_FIRST = 'FIRST_NAME_FIRST',
  LAST_NAME_FIRST = 'LAST_NAME_FIRST',
}

export enum SurveyAnswerPer {
  USER = 'USER',
  HORSE = 'HORSE',
}

export enum ArchivedStatus {
  ARCHIVED = 'ARCHIVED',
  NOT_ARCHIVED = 'NOT_ARCHIVED',
}

export enum SettlementBookingType {
  FEEDING_UPDATE = 'FEEDING_UPDATE',
  FARM_SERVICE_SUBSCRIPTION = 'FARM_SERVICE_SUBSCRIPTION',
  BEDDING_SUBSCRIPTION = 'BEDDING_SUBSCRIPTION',
  CUSTOM_BOOKING = 'CUSTOM_BOOKING',
  CREDIT_NOTE = 'CREDIT_NOTE',
  BOX_PLAN_BOOKING = 'BOX_PLAN_BOOKING',
  FARM_SERVICE = 'FARM_SERVICE',
}

export enum HayType {
  HAY = 'hay',
  HALF_HAY = 'half-hay',
}

export enum CustomBookingServiceUnit {
  PIECE = 'PIECE',
  MINUTE = 'MINUTE',
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  KILOGRAM = 'KILOGRAM',
  KILOMETER = 'KILOMETER',
  BUCKET = 'BUCKET',
  LESSON = 'LESSON',
}

export type FarmServiceSubscriptionConfiguration = {
  subscriptionSchedule: SubscriptionSchedule,
  isBookingInCurrentMonthAllowed: boolean,
  bookingInAdvance: number | null,
}

export type FarmServiceOneOffConfiguration = {
  bookingInAdvance: number,
}

export type FarmBankInformation = {
  accountHolder: string;
  iban: string;
  bankName: string;
}

export type FarmLegalInformation = {
  taxNumber: string | null;
  vatNumber: string | null;
  registerNumber: string | null;
  registrationCourt: string | null;
}

export type InvoiceConfiguration = {
  invoiceInformation: InvoiceInformation;
  areInvoicesAccessibleForUsers: boolean;
  accountingEmailAddress: EmailAddress | null;
};

export type InvoiceInformation = {
  farmAddress: FarmAddress;
  farmBankInformation: FarmBankInformation | null;
  farmTermsOfPayment: string | null;
  farmLegalInformation: FarmLegalInformation;
}

export enum InvoiceCreationType {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
}

export type HorseChangeHistory = HorseChangeHistoryEntry[];
export interface HorseChangeHistoryEntry {
  type: HorseChangeType;
  changedAt: Moment;
  idOfUser: UserId;
  nameOfUser: Name;
}
export enum HorseChangeType {
  CREATED_HORSE = 'CREATED_HORSE',
  UPDATED_NAME = 'UPDATED_NAME',
  UPDATED_EQUINE_NUMBER = 'UPDATED_EQUINE_NUMBER',
  UPDATED_IMAGE = 'UPDATED_IMAGE',
  UPDATED_CONTACTS = 'UPDATED_CONTACTS',
  UPDATED_HORSE_BREED = 'UPDATED_HORSE_BREED',
  UPDATED_HORSE_COLOR = 'UPDATED_HORSE_COLOR',
  UPDATED_HORSE_GENDER = 'UPDATED_HORSE_GENDER',
  UPDATED_STOCK_SIZE = 'UPDATED_STOCK_SIZE',
  UPDATED_BIRTH_INFORMATION = 'UPDATED_BIRTH_INFORMATION',
  UPDATED_HORSE_LINEAGE = 'UPDATED_HORSE_LINEAGE',
  UPDATED_CUSTOM_FIELDS = 'UPDATED_CUSTOM_FIELDS',
  UPDATED_COMMENT = 'UPDATED_COMMENT',
  DELETED_HORSE = 'DELETED_HORSE',
  ARCHIVED_HORSE = 'ARCHIVED_HORSE',
  CREATED_HORSE_DOCUMENT = 'CREATED_HORSE_DOCUMENT',
  DELETED_HORSE_DOCUMENT = 'DELETED_HORSE_DOCUMENT',
}

export enum HorseBreed {
  AKHAL_TEKE = 'AKHAL_TEKE',
  AEGIDIENBERGER = 'AEGIDIENBERGER',
  ALTER_REAL = 'ALTER_REAL',
  AMERICAN_PAINT_HORSE = 'AMERICAN_PAINT_HORSE',
  AMERICAN_QUARTER_HORSE = 'AMERICAN_QUARTER_HORSE',
  AMERICAN_SADDLEBRED = 'AMERICAN_SADDLEBRED',
  AMERICAN_STANDARDBRED = 'AMERICAN_STANDARDBRED',
  ANDALUSIAN_HORSE = 'ANDALUSIAN_HORSE',
  ANGLO_ARABIAN = 'ANGLO_ARABIAN',
  APPALOOSA = 'APPALOOSA',
  ARDENNAIS = 'ARDENNAIS',
  AUSTRALIAN_STOCK_HORSE = 'AUSTRALIAN_STOCK_HORSE',
  AZTECA = 'AZTECA',
  BALEARIC_HORSE = 'BALEARIC_HORSE',
  BARDIGIANO = 'BARDIGIANO',
  BASUTO_PONY = 'BASUTO_PONY',
  BAVARIAN_WARMBLOOD = 'BAVARIAN_WARMBLOOD',
  BELGIAN_WARMBLOOD = 'BELGIAN_WARMBLOOD',
  BARB_HORSE = 'BARB_HORSE',
  BLACK_FOREST_HORSE = 'BLACK_FOREST_HORSE',
  BRANDENBURGER_WARMBLOOD = 'BRANDENBURGER_WARMBLOOD',
  BUDYONNY = 'BUDYONNY',
  BOULONNAIS = 'BOULONNAIS',
  CAMARGUE_HORSE = 'CAMARGUE_HORSE',
  CASPIAN_PONY = 'CASPIAN_PONY',
  CLEVELAND_BAY = 'CLEVELAND_BAY',
  CONNEMARA_PONY = 'CONNEMARA_PONY',
  CRIOLLO = 'CRIOLLO',
  DALES_PONY = 'DALES_PONY',
  DANISH_WARMBLOOD = 'DANISH_WARMBLOOD',
  DARTMOOR_PONY = 'DARTMOOR_PONY',
  GERMAN_RIDING_PONY = 'GERMAN_RIDING_PONY',
  DON_HORSE = 'DON_HORSE',
  DULMEN_PONY = 'DULMEN_PONY',
  EAST_BULGARIAN_HORSE = 'EAST_BULGARIAN_HORSE',
  THOROUGHBRED = 'THOROUGHBRED',
  ERISKAY_PONY = 'ERISKAY_PONY',
  EXMOOR_PONY = 'EXMOOR_PONY',
  FALABELLA = 'FALABELLA',
  FELL_PONY = 'FELL_PONY',
  FINNISH_COLDBLOOD = 'FINNISH_COLDBLOOD',
  FJORD_HORSE = 'FJORD_HORSE',
  FREIBERGER = 'FREIBERGER',
  FRIESIAN_HORSE = 'FRIESIAN_HORSE',
  FRENCH_TROTTER = 'FRENCH_TROTTER',
  FURIOSO = 'FURIOSO',
  GALICENO = 'GALICENO',
  GELDERLANDER = 'GELDERLANDER',
  GOTLAND_PONY = 'GOTLAND_PONY',
  GREENLAND_HORSE = 'GREENLAND_HORSE',
  HACKNEY_HORSE = 'HACKNEY_HORSE',
  HAFLINGER = 'HAFLINGER',
  HANOVERIAN = 'HANOVERIAN',
  HOLSTEINER = 'HOLSTEINER',
  HUCUL_PONY = 'HUCUL_PONY',
  IBERIAN_HORSE = 'IBERIAN_HORSE',
  ICELANDIC_HORSE = 'ICELANDIC_HORSE',
  IRISH_DRAUGHT = 'IRISH_DRAUGHT',
  IRISH_COB = 'IRISH_COB',
  JUTLAND_HORSE = 'JUTLAND_HORSE',
  KABARDIN_HORSE = 'KABARDIN_HORSE',
  KAIMANAWA_HORSE = 'KAIMANAWA_HORSE',
  KINSKY_HORSE = 'KINSKY_HORSE',
  KLADRUBER = 'KLADRUBER',
  KNABSTRUPPER = 'KNABSTRUPPER',
  KONIK = 'KONIK',
  CROSSBREED_HORSE = 'CROSSBREED_HORSE',
  KUSHUM = 'KUSHUM',
  KYRGYZ_HORSE = 'KYRGYZ_HORSE',
  LIPIZZANER = 'LIPIZZANER',
  LUSITANO = 'LUSITANO',
  LEWITZER = 'LEWITZER',
  MANGALARGA_MARCHADOR = 'MANGALARGA_MARCHADOR',
  MARWARI = 'MARWARI',
  MECKLENBURG_WARMBLOOD = 'MECKLENBURG_WARMBLOOD',
  MENORQUIN_HORSE = 'MENORQUIN_HORSE',
  MERENS_HORSE = 'MERENS_HORSE',
  MISSOURI_FOX_TROTTER = 'MISSOURI_FOX_TROTTER',
  MONGOLIAN_HORSE = 'MONGOLIAN_HORSE',
  MORGAN_HORSE = 'MORGAN_HORSE',
  MURGESE = 'MURGESE',
  MUSTANG = 'MUSTANG',
  NONIUS_HORSE = 'NONIUS_HORSE',
  NORIKER_HORSE = 'NORIKER_HORSE',
  NORWEGIAN_FJORD_HORSE = 'NORWEGIAN_FJORD_HORSE',
  NEW_FOREST_PONY = 'NEW_FOREST_PONY',
  OLDENBURG_HORSE = 'OLDENBURG_HORSE',
  ORLOV_TROTTER = 'ORLOV_TROTTER',
  PAINT_HORSE = 'PAINT_HORSE',
  PASO_FINO = 'PASO_FINO',
  PERCHERON = 'PERCHERON',
  PERUVIAN_PASO = 'PERUVIAN_PASO',
  PINTO_HORSE = 'PINTO_HORSE',
  QUARAB = 'QUARAB',
  QUARTER_PONY = 'QUARTER_PONY',
  RACKING_HORSE = 'RACKING_HORSE',
  ROCKY_MOUNTAIN_HORSE = 'ROCKY_MOUNTAIN_HORSE',
  SAXON_WARMBLOOD = 'SAXON_WARMBLOOD',
  ITALIAN_SADDLE_HORSE = 'ITALIAN_SADDLE_HORSE',
  SELLE_FRANCAIS = 'SELLE_FRANCAIS',
  SHAGYA_ARABIAN = 'SHAGYA_ARABIAN',
  SHIRE_HORSE = 'SHIRE_HORSE',
  SORRAIA = 'SORRAIA',
  SUFFOLK_PUNCH = 'SUFFOLK_PUNCH',
  TENNESSEE_WALKING_HORSE = 'TENNESSEE_WALKING_HORSE',
  TRAKEHNER = 'TRAKEHNER',
  TERSK_HORSE = 'TERSK_HORSE',
  GYPSY_VANNER = 'GYPSY_VANNER',
  HUNGARIAN_WARMBLOOD = 'HUNGARIAN_WARMBLOOD',
  VLAAMPERD = 'VLAAMPERD',
  WALER_HORSE = 'WALER_HORSE',
  WELSH_PONY = 'WELSH_PONY',
  WESTPHALIAN_HORSE = 'WESTPHALIAN_HORSE',
  WURTTEMBERG_WARMBLOOD = 'WURTTEMBERG_WARMBLOOD',
  ZWEIBRUCKER = 'ZWEIBRUCKER',
}

export enum HorseColor {
  BLACK = 'BLACK',
  BAY = 'BAY',
  CHESTNUT = 'CHESTNUT',
  TRUE_BLACK = 'TRUE_BLACK',
  GREY = 'GREY',
  PINTO = 'PINTO',
}

export enum HorseGender {
  MARE = 'MARE',
  GELDING = 'GELDING',
  STALLION = 'STALLION',
}

export type HorseLineage = {
  nameOfFather: string | null;
  equineNumberOfFather: EquineNumber | null;
  nameOfGrandfatherOnFatherSide: string | null;
  equineNumberOfGrandfatherOnFatherSide: EquineNumber | null;
  nameOfGrandmotherOnFatherSide: string | null;
  equineNumberOfGrandmotherOnFatherSide: EquineNumber | null;
  nameOfMother: string | null;
  equineNumberOfMother: EquineNumber | null;
  nameOfGrandfatherOnMotherSide: string | null;
  equineNumberOfGrandfatherOnMotherSide: EquineNumber | null;
  nameOfGrandmotherOnMotherSide: string | null;
  equineNumberOfGrandmotherOnMotherSide: EquineNumber | null;
}
