import { render, staticRenderFns } from "./vaccination-statuses.vue?vue&type=template&id=08dd09e0&scoped=true&"
import script from "./vaccination-statuses.vue?vue&type=script&setup=true&lang=ts&"
export * from "./vaccination-statuses.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./vaccination-statuses.vue?vue&type=style&index=0&id=08dd09e0&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08dd09e0",
  null
  
)

export default component.exports