import { attachFarmAndUserProperties, attachFarmAndUserPropertiesToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { apiUrl, performApiRequest, performApiRequestForFile, performApiRequestWithFiles } from '@/infrastructure';
import { FileResponse } from '@/application/types';
import { GetHorsesAsManager, GetHorseAsManager, GetActiveGerblhofFeedingForHorseAsManager, UpdateBreedOfHorseAsManager, UpdateColorOfHorseAsManager, UpdateGenderOfHorseAsManager, UpdateStockSizeOfHorseAsManager, UpdateCommentForHorseAsManager, UpdateBirthInformationOfHorseAsManager, UpdateLineageOfHorseAsManager, UpdateFarrierOfHorseAsManager, UpdateVeterinarianOfHorseAsManager, GetHorse, UpdateVeterinarianOfHorse, UpdateFarrierOfHorse, UpdateEquineNumberOfHorse, UpdateStockSizeOfHorse, UpdateBreedOfHorse, UpdateColorOfHorse, UpdateGenderOfHorse, UpdateBirthInformationOfHorse, UpdateLineageOfHorse, UpdateHorseImage, UpdateImageOfHorseAsManager, GetCustomFieldsAsManager, CreateCustomFieldAsManager, UpdateLabelOfCustomFieldAsManager, UpdateCustomFieldsOfHorseAsManager, DeleteCustomFieldAsManager, UpdateOptionsOfCustomFieldAsManager, GetCustomFields, UpdateCustomFieldsOfHorse, GetBoxSignAsManager, GetDocumentsForHorseAsManager, GetDocumentPDFAsManager, GetBoxSignGenerationProcessesForAllHorsesAsManager, GetContactListForHorsesAsPDFAsManager, GetBoxSignsForAllHorsesAsManager, UpdateHorseNameAsManager, DeleteHorseAsManager, DeleteBoxSignGenerationProcessForAllHorsesAsManager, UpdateEquineNumberAsManager, CreateHorseDocumentAsManager, DeleteHorseDocumentAsManager, ArchiveHorseAsManager, GenerateBoxSignsForAllHorsesAsManager, AcceptHorseShareInvite, RejectHorseShareInvite, GetAvailableHorses, GetHorseShareInvite, GetHorseSharesForHorse, GetHorseShareInvitesForHorse, GetBoxSign, GetHorseShareInvitesAsInvitedUser, CreateHorse, UpdateHorseName, InviteUserAsHorseShare, RevokeHorseShareInvite, RevokeHorseShare, DeleteHorse } from './types';

// -- Queries

export function getHorsesAsManager(query: GetHorsesAsManager.GetHorsesAsManagerQuery): Promise<GetHorsesAsManager.Response> {
  const url = `${apiUrl}/api/horse-management/get-horses-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getHorseAsManager(query: GetHorseAsManager.GetHorseAsManagerQuery): Promise<GetHorseAsManager.Horse> {
  const url = `${apiUrl}/api/horse-management/get-horse-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getActiveGerblhofFeedingForHorseAsManager(
  query: GetActiveGerblhofFeedingForHorseAsManager.GetActiveGerblhofFeedingForHorseAsManagerQuery
): Promise<GetActiveGerblhofFeedingForHorseAsManager.ActiveGerblhofFeeding> {
  const url = `${apiUrl}/api/horse-management/get-active-gerblhof-feeding-for-horse-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getHorse(query: GetHorse.GetHorseQuery): Promise<GetHorse.Horse> {
  const url = `${apiUrl}/api/horse-management/get-horse-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getCustomFields(
  query: GetCustomFields.GetCustomFieldsQuery
): Promise<GetCustomFields.CustomField[]> {
  const url = `${apiUrl}/api/horse-management/get-custom-fields-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getCustomFieldsAsManager(
  query: GetCustomFieldsAsManager.GetCustomFieldsAsManagerQuery
): Promise<GetCustomFieldsAsManager.CustomField[]> {
  const url = `${apiUrl}/api/horse-management/get-custom-fields-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getBoxSignAsManager(query: GetBoxSignAsManager.GetBoxSignAsManagerQuery): Promise<FileResponse> {
  const url = `${apiUrl}/api/horse-management/get-box-sign-as-manager-query`;
  return performApiRequestForFile({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getBoxSignGenerationProcessesForAllHorsesAsManager(
  query: GetBoxSignGenerationProcessesForAllHorsesAsManager.GetBoxSignGenerationProcessesForAllHorsesAsManagerQuery
): Promise<GetBoxSignGenerationProcessesForAllHorsesAsManager.BoxSignGenerationProcessForAllHorses[]> {
  const url = `${apiUrl}/api/horse-management/get-box-sign-generation-processes-for-all-horses-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getBoxSignsForAllHorsesAsManager(
  query: GetBoxSignsForAllHorsesAsManager.GetBoxSignsForAllHorsesAsManagerQuery
): Promise<FileResponse> {
  const url = `${apiUrl}/api/horse-management/get-box-signs-for-all-horses-as-manager-query`;
  return performApiRequestForFile({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getContactListForHorsesAsPDFAsManager(
  query: GetContactListForHorsesAsPDFAsManager.GetContactListForHorsesAsPDFAsManagerQuery
): Promise<FileResponse> {
  const url = `${apiUrl}/api/horse-management/get-contact-list-for-horses-as-pdf-as-manager-query`;
  return performApiRequestForFile({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getDocumentsForHorseAsManager(
  query: GetDocumentsForHorseAsManager.GetDocumentsForHorseAsManagerQuery
): Promise<GetDocumentsForHorseAsManager.HorseDocument[]> {
  const url = `${apiUrl}/api/horse-management/get-documents-for-horse-as-manager-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getDocumentPDFAsManager(query: GetDocumentPDFAsManager.GetDocumentPDFAsManagerQuery): Promise<FileResponse> {
  const url = `${apiUrl}/api/horse-management/get-document-pdf-as-manager-query`;
  return performApiRequestForFile({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getAvailableHorses(query: GetAvailableHorses.GetAvailableHorsesQuery): Promise<GetAvailableHorses.AvailableHorses> {
  const url = `${apiUrl}/api/horse-management/get-available-horses-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getHorseShareInvite(
  query: GetHorseShareInvite.GetHorseShareInviteQuery
): Promise<GetHorseShareInvite.HorseShareInviteAsInvitedUser> {
  const url = `${apiUrl}/api/horse-management/get-horse-share-invite-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getHorseShareInvitesAsInvitedUser(
  query: GetHorseShareInvitesAsInvitedUser.GetHorseShareInvitesAsInvitedUserQuery
): Promise<GetHorseShareInvitesAsInvitedUser.HorseShareInviteAsInvitedUser[]> {
  const url = `${apiUrl}/api/horse-management/get-horse-share-invites-as-invited-user-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getHorseSharesForHorse(
  query: GetHorseSharesForHorse.GetHorseSharesForHorseQuery
): Promise<GetHorseSharesForHorse.HorseShare[]> {
  const url = `${apiUrl}/api/horse-management/get-horse-shares-for-horse-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getHorseShareInvitesForHorse(
  query: GetHorseShareInvitesForHorse.GetHorseShareInvitesForHorseQuery
): Promise<GetHorseShareInvitesForHorse.HorseShareInvite[]> {
  const url = `${apiUrl}/api/horse-management/get-horse-share-invites-for-horse-query`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function getBoxSign(query: GetBoxSign.GetBoxSignQuery): Promise<FileResponse> {
  const url = `${apiUrl}/api/horse-management/get-box-sign-query`;
  return performApiRequestForFile({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

// -- Commands

export function updateBreedOfHorse(query: UpdateBreedOfHorse.UpdateBreedOfHorseCommand): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-breed-of-horse-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function updateBreedOfHorseAsManager(query: UpdateBreedOfHorseAsManager.UpdateBreedOfHorseAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-breed-of-horse-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function updateColorOfHorse(query: UpdateColorOfHorse.UpdateColorOfHorseCommand): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-color-of-horse-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function updateColorOfHorseAsManager(query: UpdateColorOfHorseAsManager.UpdateColorOfHorseAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-color-of-horse-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function updateGenderOfHorse(query: UpdateGenderOfHorse.UpdateGenderOfHorseCommand): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-gender-of-horse-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function updateGenderOfHorseAsManager(query: UpdateGenderOfHorseAsManager.UpdateGenderOfHorseAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-gender-of-horse-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function updateStockSizeOfHorse(query: UpdateStockSizeOfHorse.UpdateStockSizeOfHorseCommand): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-stock-size-of-horse-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function updateStockSizeOfHorseAsManager(
  query: UpdateStockSizeOfHorseAsManager.UpdateStockSizeOfHorseAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-stock-size-of-horse-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function updateBirthInformationOfHorse(query: UpdateBirthInformationOfHorse.UpdateBirthInformationOfHorseCommand): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-birth-information-of-horse-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function updateBirthInformationOfHorseAsManager(
  query: UpdateBirthInformationOfHorseAsManager.UpdateBirthInformationOfHorseAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-birth-information-of-horse-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function updateLineageOfHorse(query: UpdateLineageOfHorse.UpdateLineageOfHorseCommand): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-lineage-of-horse-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function updateLineageOfHorseAsManager(
  query: UpdateLineageOfHorseAsManager.UpdateLineageOfHorseAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-lineage-of-horse-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function updateCommentForHorseAsManager(
  query: UpdateCommentForHorseAsManager.UpdateCommentForHorseAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-comment-for-horse-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function updateFarrierOfHorseAsManager(
  query: UpdateFarrierOfHorseAsManager.UpdateFarrierOfHorseAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-farrier-of-horse-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function updateFarrierOfHorse(
  query: UpdateFarrierOfHorse.UpdateFarrierOfHorseCommand
): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-farrier-of-horse-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function updateVeterinarianOfHorseAsManager(
  query: UpdateVeterinarianOfHorseAsManager.UpdateVeterinarianOfHorseAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-veterinarian-of-horse-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function updateVeterinarianOfHorse(query: UpdateVeterinarianOfHorse.UpdateVeterinarianOfHorseCommand): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-veterinarian-of-horse-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function updateEquineNumberOfHorse(query: UpdateEquineNumberOfHorse.UpdateEquineNumberOfHorseCommand): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-equine-number-of-horse-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function updateHorseImage(query: UpdateHorseImage.UpdateHorseImageCommand): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-horse-image-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: attachFarmAndUserPropertiesToCommandWithFiles(query) });
}

export function updateImageOfHorseAsManager(query: UpdateImageOfHorseAsManager.UpdateImageOfHorseAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-image-of-horse-as-manager-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: attachFarmAndUserPropertiesToCommandWithFiles(query) });
}

export function createCustomFieldAsManager(query: CreateCustomFieldAsManager.CreateCustomFieldAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/horse-management/create-custom-field-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function updateLabelOfCustomFieldAsManager(
  query: UpdateLabelOfCustomFieldAsManager.UpdateLabelOfCustomFieldAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-label-of-custom-field-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function updateOptionsOfCustomFieldAsManager(
  query: UpdateOptionsOfCustomFieldAsManager.UpdateOptionsOfCustomFieldAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-options-of-custom-field-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function deleteCustomFieldAsManager(query: DeleteCustomFieldAsManager.DeleteCustomFieldAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/horse-management/delete-custom-field-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function updateCustomFieldsOfHorse(
  query: UpdateCustomFieldsOfHorse.UpdateCustomFieldsOfHorseCommand
): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-custom-fields-of-horse-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function updateCustomFieldsOfHorseAsManager(
  query: UpdateCustomFieldsOfHorseAsManager.UpdateCustomFieldsOfHorseAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-custom-fields-of-horse-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(query) });
}

export function updateHorseNameAsManager(command: UpdateHorseNameAsManager.UpdateHorseNameAsManagerWorkflow): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-horse-name-as-manager-workflow`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function deleteHorseAsFarmManager(command: DeleteHorseAsManager.DeleteHorseAsManagerWorkflow): Promise<void> {
  const url = `${apiUrl}/api/horse-management/delete-horse-as-manager-workflow`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function generateBoxSignsForAllHorsesAsManager(
  command: GenerateBoxSignsForAllHorsesAsManager.GenerateBoxSignsForAllHorsesAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/horse-management/generate-box-signs-for-all-horses-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function deleteBoxSignGenerationProcessForAllHorsesAsManager(
  command: DeleteBoxSignGenerationProcessForAllHorsesAsManager.DeleteBoxSignGenerationProcessForAllHorsesAsManagerCommand
): Promise<void> {
  const url = `${apiUrl}/api/horse-management/delete-box-sign-generation-process-for-all-horses-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function updateEquineNumberAsManager(command: UpdateEquineNumberAsManager.UpdateEquineNumberAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-equine-number-as-manager-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function createHorseDocumentAsManager(command: CreateHorseDocumentAsManager.CreateHorseDocumentAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/horse-management/create-horse-document-as-manager-command`;
  return performApiRequestWithFiles({ url, method: 'POST', data: attachFarmAndUserPropertiesToCommandWithFiles(command) });
}

export function deleteHorseDocumentAsManager(command: DeleteHorseDocumentAsManager.DeleteHorseDocumentAsManagerCommand): Promise<void> {
  const url = `${apiUrl}/api/horse-management/delete-horse-document-as-manager-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function archiveHorseAsManager(command: ArchiveHorseAsManager.ArchiveHorseAsManagerWorkflow): Promise<void> {
  const url = `${apiUrl}/api/horse-management/archive-horse-as-manager-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function acceptHorseShareInvite(command: AcceptHorseShareInvite.AcceptHorseShareInviteCommand): Promise<void> {
  const url = `${apiUrl}/api/horse-management/accept-horse-share-invite-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function rejectHorseShareInvite(command: RejectHorseShareInvite.RejectHorseShareInviteCommand): Promise<void> {
  const url = `${apiUrl}/api/horse-management/reject-horse-share-invite-command`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function createHorse(command: CreateHorse.CreateHorseWorkflow): Promise<void> {
  const url = `${apiUrl}/api/horse-management/create-horse-workflow`;
  return performApiRequestWithFiles<void>({ url, method: 'POST', data: attachFarmAndUserPropertiesToCommandWithFiles(command) });
}

export function updateHorseName(command: UpdateHorseName.UpdateHorseNameWorkflow): Promise<void> {
  const url = `${apiUrl}/api/horse-management/update-horse-name-workflow`;
  return performApiRequest<void>({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function inviteUserAsHorseShare(command: InviteUserAsHorseShare.InviteUserAsHorseShareCommand): Promise<void> {
  const url = `${apiUrl}/api/horse-management/invite-user-as-horse-share-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function revokeHorseShareInvite(command: RevokeHorseShareInvite.RevokeHorseShareInviteCommand): Promise<void> {
  const url = `${apiUrl}/api/horse-management/revoke-horse-share-invite-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function revokeHorseShare(command: RevokeHorseShare.RevokeHorseShareCommand): Promise<void> {
  const url = `${apiUrl}/api/horse-management/revoke-horse-share-command`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}

export function deleteHorse(command: DeleteHorse.DeleteHorseWorkflow): Promise<void> {
  const url = `${apiUrl}/api/horse-management/delete-horse-workflow`;
  return performApiRequest({ url, method: 'POST', data: attachFarmAndUserProperties(command) });
}
